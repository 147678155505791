import React, { useContext } from 'react';
import PropType from 'prop-types';
import { useTranslation } from 'react-i18next';

// Context
import HelpSectionContext from '../../state-management/context/HelpSectionContext';
import { SELECT_ARTICLE } from '../../state-management/actions/actionTypes';

// Components
import Icon from '../shared/Icon';

const HelpSectionArticle = ({ description, iconClass, subtext, title }) => {
    // eslint-disable-next-line no-unused-vars
    const [helpSectionState, helpSectionDispatch] = useContext(HelpSectionContext);

    // Translation hook
    const { t } = useTranslation();

    const handleArticleClick = () => {
        helpSectionDispatch({
            type: SELECT_ARTICLE,
            article: { description, subtext, title },
            text: '',
        });
    };

    return (
        <div className="gov__popular-article">
            {/* Container keeps the image at the correct height, should be looking into */}
            <div className="gov__lightbulb-icon-container">
                <Icon icon={iconClass} label="Lightbulb icon" />
            </div>
            <div>
                <h4>{title}</h4>
                <p>{subtext}</p>
                <div className="gov__help-section-read-more">
                    <div className="gov__link" role="button" aria-pressed="false" onClick={handleArticleClick}>{t('help.read-more')}</div>
                    <Icon icon="gov__chevron right help-section" label="Arrow right" />
                </div>
            </div>
        </div>
    );
};

HelpSectionArticle.propTypes = {
    description: PropType.string,
    iconClass: PropType.string,
    subtext: PropType.string,
    title: PropType.string,
};

HelpSectionArticle.defaultProps = {
    description: '',
    iconClass: '',
    subtext: '',
    title: '',
};

export default HelpSectionArticle;
