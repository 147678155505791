import { ComponentChildren, Fragment, h } from 'preact'
import { ToggleFullScreen } from 'components/FullScreen'
import { Overlay, OverlayError, OverlayMessage } from './Overlay'
import { DocumentSides } from '~types/commons'
import { Context } from './Machines/DocumentAutoMachine'
import style from './DocumentAutoOverlay.scss'
import Instructions from './Instructions'
import CameraButton from 'components/Button/CameraButton'
import { useLocales } from '~core/localisation'
import { RenderFallbackProp } from '~types/routers'
import { parseTags } from '~utils'
import errors from '../strings/errors'
import { DocumentTypes } from '~types/steps'
import { getLocalisationStrings } from './localisation'
import { TrackScreenCallback } from '~types/hocs'
import { BaseDocumentAnalytics } from './Analytics/types'

type FooterProps = {
  title: string
  isAutoCapture: boolean
  onCapture: () => void
  value: string
}

const Footer = ({ title, isAutoCapture, onCapture, value }: FooterProps) => {
  return (
    <div className={style.footer}>
      <Instructions title={title} />
      <div>
        {!isAutoCapture && (
          <CameraButton
            ariaLabel={'doc_auto_capture.button_accessibility'}
            onClick={() => onCapture()}
            className={style.btn}
            disableInteraction={value !== 'idle'}
          />
        )}
      </div>
    </div>
  )
}

export type DocumentAutoOverlayProps = {
  children: ComponentChildren
  side: DocumentSides
  documentType: DocumentTypes
  onCapture: () => void
  context: Context
  value: string
  renderFallback: RenderFallbackProp
  trackScreen: TrackScreenCallback
  documentAnalytics: BaseDocumentAnalytics
  isUploadFallbackDisabled: boolean
  isVideo: boolean
}

export const DocumentAutoOverlay = ({
  children,
  onCapture,
  side,
  documentType,
  value,
  context: { isAutoCapture, detectionError },
  renderFallback,
  trackScreen,
  documentAnalytics,
  isUploadFallbackDisabled,
  isVideo,
}: DocumentAutoOverlayProps) => {
  const { translate } = useLocales()
  const locales = getLocalisationStrings(side, documentType, isVideo)

  const getStatus = () => {
    switch (value) {
      case 'captured':
      case 'submitting':
        return 'success'
      case 'capturing':
        return 'capture'
      default:
        return 'idle'
    }
  }

  const renderHeader = () => {
    if (value === 'errored') {
      const { message, instruction } = errors[
        isUploadFallbackDisabled
          ? 'CAMERA_NOT_WORKING_NO_FALLBACK'
          : 'CAMERA_NOT_WORKING'
      ]

      return (
        <OverlayError title={translate(message)}>
          {parseTags(translate(instruction), ({ text, type }) => {
            trackScreen('fallback_displayed', documentAnalytics)
            return renderFallback({ text, type }, () => {
              trackScreen('fallback_triggered', documentAnalytics)
            })
          })}
        </OverlayError>
      )
    }
  }

  const renderFrame = () => {
    if (value === 'capturing') {
      return (
        <OverlayMessage spinner={true} message={translate(locales.holdStill)} />
      )
    } else if (value === 'flipping') {
      return <OverlayMessage message={translate(locales.flipDocument)} />
    } else if (detectionError) {
      return <OverlayMessage message={translate(locales.noDocument)} />
    }
  }

  const renderFooter = () => {
    const getTitle = () => {
      if (!isAutoCapture && (value === 'idle' || value === 'stopped')) {
        return locales.fallbackTakePhoto
      }
      switch (value) {
        case 'capturing':
          return locales.capturing
        case 'captured':
        case 'submitting':
          return locales.captured
        default:
          return locales.positionDocument
      }
    }

    return (
      <Footer
        title={translate(getTitle(), { side })}
        isAutoCapture={isAutoCapture}
        onCapture={onCapture}
        value={value}
      />
    )
  }

  return (
    <Fragment>
      <ToggleFullScreen />
      <div data-page-id="DocumentAutoCapture" className={style.docAutoCapture}>
        <Overlay
          header={renderHeader()}
          frame={renderFrame()}
          footer={renderFooter()}
          status={getStatus()}
        >
          {children}
        </Overlay>
      </div>
    </Fragment>
  )
}
