import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { i18nextPlugin } from 'translation-check'

// Translation files
import englishTranslations from './en_new.json';
import dutchTranslations from './nl_new.json';
import germanTranslations from './de_new.json';
import polishTranslations from './pl_new.json';
import frenchTranslations from './fr_new.json';
import spanishTranslations from './es_new.json';

const translations = {
    de: {
        translation: germanTranslations,
    },
    en: {
        translation: englishTranslations,
    },
    nl: {
        translation: dutchTranslations,
    },
    pl: {
        translation: polishTranslations,
    },
    fr: {
        translation: frenchTranslations,
    },
    es: {
        translation: spanishTranslations,
    },
};

i18n.use(i18nextPlugin).init({
    resources: translations,
    lng: sessionStorage.getItem('language') || 'en',
    interpolation: {
        escapeValue: false,
    },
});

i18n.use(initReactI18next).init({
    resources: translations,
    lng: sessionStorage.getItem('language') || 'en',
    interpolation: {
        escapeValue: false,
    },
});

export default i18n;
