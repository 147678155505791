import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

// Context
import HelpSectionContext from '../../state-management/context/HelpSectionContext';
import { SELECT_VIEW } from '../../state-management/actions/actionTypes';

// Components
import Button from '../shared/Button';

// Consts
import helpSectionViews from '../../consts/helpSectionViews';

const HelpSectionNeedToSpeakToSomeone = () => {
    // eslint-disable-next-line
    const [helpSectionState, helpSectionDispatch] = useContext(HelpSectionContext);

    // Translation hook
    const { t } = useTranslation();

    return (
        <div className="gov__need-to-speak">
            <p>{t('help.contact-us.title')}</p>

            <p>{t('help.contact-us.description')}</p>

            <Button
                onClickHandler={() => helpSectionDispatch({ type: SELECT_VIEW, currentView: helpSectionViews.CONTACT_US })}
                text={t('help.contact-us.button-text')}
            />
        </div>
    );
};

export default HelpSectionNeedToSpeakToSomeone;
