import React from 'react';
import PropType from 'prop-types';

// Components
import Icon from '../shared/Icon';
import {useTranslation} from 'react-i18next';

const DocumentCombinationTile = ({documentCombination, handleDocumentCombinationSelection}) => {
  // Translation hook
  const {t} = useTranslation();

  return (
    <div
      className="gov__document-combination"
      role="button"
      aria-pressed="false"
      onClick={() => handleDocumentCombinationSelection(documentCombination)}
    >
      {documentCombination.category === 'permanent' &&
      <div className="gov__document-combination-title">
        <p className="gov__document-combination-title">{t('document-selection.documents-needed')}</p>
        <Icon icon="gov__document-combo-permanent" label="Long term right to work icon"/>
      </div>
      }

      {documentCombination.category !== 'permanent' &&
      <p className="gov__document-combination-title">{t('document-selection.documents-needed')}</p>
      }

      <div className="gov__document-combination-content">
        <div>
          {documentCombination.documents.map((document, index) => <p key={index}>{document.name}</p>)}
        </div>

        <div className="gov__document-combination-section">
          <Icon icon="gov__chevron right" label="Arrow right"/>
        </div>
      </div>
    </div>
  );
};

DocumentCombinationTile.propTypes = {
  documentCombination: PropType.object,
  handleDocumentCombinationSelection: PropType.func,
};

DocumentCombinationTile.defaultProps = {
  documentCombination: null,
  handleDocumentCombinationSelection: null,
};

export default DocumentCombinationTile;
