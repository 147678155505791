import React from 'react';
import PropTypes from 'prop-types'; // Corrected from 'PropType' to 'PropTypes'
import { useTranslation } from "react-i18next";
import Modal from './Modal'; // Added import statement

const ModalShareCode = ({ setLoadingModal, shareCodeDataDoNotMatch, setShareCodeDataDoNotMatch, label }) => {
  const { t } = useTranslation();
  const handleCloseModal = () => {
    setLoadingModal(false);
    setShareCodeDataDoNotMatch(false);
  };

  return (
    <div className='gov__form-group'>
      <Modal
        updateModal={(event) => handleCloseModal(event)}
        className='gov_modal_data-do-not-match_picker_container'
      >
        {/* Data do not match modal */}
        {shareCodeDataDoNotMatch && (
          <div className='gov_modal_data-do-not-match'>
            {/* Title */}
            <p className='gov_modal_data-do-not-match_title'>
              {label}
            </p>

            {/* confirm and cancel btn */}
            <div className='gov_modal_data-do-not-match-btn-container'>
              <button
                className='gov__modal_btn gov_modal_data-do-not-match_btn_confirm'
                onClick={handleCloseModal}
              >
                {t('shared.ok')}
              </button>
            </div>
          </div>
        )}

        {/* Loading State */}
        {!shareCodeDataDoNotMatch && (
          <div className='gov_modal_data-do-not-match'>
            <div className='gov__loading-container-loading'>
              <div className='gov__loading-spinner-container-loading'>
                <div></div>
              </div>

              <div className='gov__loading-message-container'>
                <p>{t('document-capture.share-code.loading')}</p>
              </div>
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
};

ModalShareCode.propTypes = {
  setLoadingModal: PropTypes.func,
  shareCodeDataDoNotMatch: PropTypes.bool,
  setShareCodeDataDoNotMatch: PropTypes.func,
  label: PropTypes.string,
};

ModalShareCode.defaultProps = {
  setLoadingModal: null,
  shareCodeDataDoNotMatch: false,
  setShareCodeDataDoNotMatch: null,
  label: '',
};

export default ModalShareCode;
