import React, { useContext } from 'react';
import PropType from 'prop-types';
import i18next from 'i18next';

// Context
import UserContext from '../../../state-management/context/UserContext';

// Components
import Icon from '../Icon';

// Consts
import { SET_LANGUAGE } from '../../../state-management/actions/actionTypes';

const LanguageRow = ({ iconClass, isoCode, languageDescription }) => {
    //eslint-disable-next-line
    const [userState, userDispatch] = useContext(UserContext);

    const handleChangeLanguageClick = () => {
        i18next.changeLanguage(isoCode);
        sessionStorage.setItem('language', isoCode);
        userDispatch({ type: SET_LANGUAGE, currentLanguage: isoCode, sidebarStatus: 'hidden' });
    };

    const isCurrentLanguage = () => isoCode === userState.currentLanguage;

    return (
        <div className="gov__language-row" role="button" aria-pressed="false" onClick={() => handleChangeLanguageClick(isoCode)}>
            <div className="gov__icon-and-title">
                <Icon icon={iconClass} label="Language icon" />
                <p>{languageDescription}</p>
            </div>

            { isCurrentLanguage() && <Icon icon="gov__tick" label="Tick for selected language" /> }
        </div>
    );
};

LanguageRow.propTypes = {
    iconClass: PropType.string,
    isoCode: PropType.string,
    languageDescription: PropType.string,
};

LanguageRow.defaultProps = {
    iconClass: 'gov__language-icon gb',
    languageCode: 'en',
    languageDescription: 'English - United Kingdom',
};

export default LanguageRow;
