import { Fragment, h } from 'preact'
import { useEffect, useState } from 'preact/hooks'
import Webcam from 'react-webcam'
import { DocumentTypes } from '~types/steps'
import {
  HandleCaptureProp,
  HandleDocMultiFrameCaptureProp,
  RenderFallbackProp,
} from '~types/routers'
import { DocumentSides } from '~types/commons'
import {
  WithFailureHandlingProps,
  WithPermissionsFlowProps,
  WithTrackingProps,
} from '~types/hocs'
import { useContainerDimensions } from '~contexts'

import { autoCaptureMachine } from './Machines/DocumentAutoMachine'
import { useReactWebcam } from './useReactWebcam'
import style from './DocumentAuto.scss'
import { appendToTracking, trackException } from '../../Tracker'
import { defaultParams } from './OpenCV/DetectionParams'
import { blurValidation, BlurDetectionResult } from './OpenCV/BlurDetection'
import { handleCvExceptions, waitForOpenCV } from './OpenCV/waitForOpenCv'
import { edgeValidation, EdgeDetectionResults } from './OpenCV/EdgeDetection'
import { DevTools } from './DevTools/DevTools'
import {
  appendFileName,
  getDeviceInfo,
  getImageData,
  imageDataToBlob,
  scalePlaceholderToVideo,
} from './utils'
import Spinner from '../Spinner'
import withPermissionsFlow from '../CameraPermissions/withPermissionsFlow'
import withFailureHandling from '../Camera/withFailureHandling'
import { CameraProps, WithOnUserMediaProps } from '~types/camera'
import { createMultiCaptureBlurMachine } from './Machines/MultiCaptureBlurMachine'
import { getBooleanSearchParam } from './DevTools/tools'
import { DocumentAutoOverlay } from './DocumentAutoOverlay'
import { BaseDocumentAnalytics } from './Analytics/types'
import { createMultiFrameCaptureMachine } from './Machines/MultiFrameCaptureMachine'
import { StateValue } from 'xstate'
import { useAnalytics } from './Analytics/useAnalytics'
import { DocumentAutoTestPage } from './DevTools/DocumentAutoTestPage'
import { useMediaRecorder } from '~webcam/useMediaRecorder'
import { useMachine } from './useMachine'

// These exist to capture white document on a white background. We don't want to start with this "Easy" detection mode right away because otherwise the capture is too trigger happy.
const FALLBACK_CANNY_DETETECTION_PARAMS = {
  aperture: 3,
  threshold1: 25,
  threshold2: 75,
}

export type DocumentAutoProps = {
  cameraClassName?: string
  documentType: DocumentTypes
  renderFallback: RenderFallbackProp
  onCapture: HandleCaptureProp
  onMultiFrameCapture: HandleDocMultiFrameCaptureProp
  side: DocumentSides
  isMultiFrameCapture: boolean
  isAutoCapture: boolean
  autoCaptureTimeoutMilliseconds: number
  documentAnalytics: BaseDocumentAnalytics
  imageQualityRetries?: number
} & CameraProps &
  WithOnUserMediaProps &
  WithTrackingProps &
  WithFailureHandlingProps &
  WithPermissionsFlowProps

const DocumentAutoBase = ({
  onCapture,
  onMultiFrameCapture,
  side,
  documentType,
  onFailure,
  isMultiFrameCapture,
  isAutoCapture,
  autoCaptureTimeoutMilliseconds,
  documentAnalytics,
  trackScreen,
  renderFallback,
  isUploadFallbackDisabled,
  imageQualityRetries = 0,
}: DocumentAutoProps) => {
  const enableDevtools = getBooleanSearchParam('documentAutoCaptureDebug')
  const enableTestPage = getBooleanSearchParam('documentAutoTestPage')
  const [params, setParams] = useState({
    ...defaultParams,
    debug: enableDevtools,
  })
  const container = useContainerDimensions()

  const [edgeDetection, setEdgeDetection] = useState<
    EdgeDetectionResults | undefined
  >()

  const { webcamProps, webcamRef, waitForVideo } = useReactWebcam(onFailure)

  const analytics = useAnalytics(
    params,
    documentAnalytics,
    trackScreen,
    webcamRef,
    webcamProps
  )

  useEffect(() => {
    analytics.trackJsDocumentCaptureScreen()
  }, [])

  const { startCapture, stopCapture } = useMediaRecorder(
    webcamRef?.current?.stream || null
  )

  const [{ context, value }, send] = useMachine(autoCaptureMachine, {
    context: {
      side,
      isAutoCapture,
      isVideo: isMultiFrameCapture,
      autocaptureTimeout: autoCaptureTimeoutMilliseconds,
    },
    services: {
      detectDocument: ({ timer }) =>
        new Promise<boolean>((resolve, reject) => {
          const image = getImageData(webcamRef.current)
          const video = webcamRef.current?.video
          const frame = document
            .getElementById('frame')
            ?.getBoundingClientRect()

          if (!video || !frame || !image || !container) {
            return reject('VIDEO_NOT_READY')
          }

          const rectangle = scalePlaceholderToVideo(container, video, frame)
          const shouldUseFallbackParams = Date.now() - timer > 3000
          const edgeValidationParams = {
            ...params,
            ...{
              canny: shouldUseFallbackParams
                ? FALLBACK_CANNY_DETETECTION_PARAMS
                : params.canny,
            },
          }
          shouldUseFallbackParams &&
            analytics.collectFallbackParams(edgeValidationParams)
          analytics.collectDetectionRectangle(rectangle)

          return waitForOpenCV()
            .then((cv) => {
              const result = edgeValidation(
                cv,
                edgeValidationParams,
                rectangle,
                image
              )
              setEdgeDetection(result)
              analytics.collectEdgeResults(result, edgeValidationParams)
              resolve(
                result.segments.totalDetected >=
                  edgeValidationParams.edgeDetection.requiredEdges
              )
            })
            .catch((err) => {
              const openCvErr = handleCvExceptions(err)
              console.error(openCvErr)
              reject(err)
            })
        }),
      captureVideo: createMultiFrameCaptureMachine({
        startRecording: () =>
          new Promise<void>((resolve, reject) => {
            if (!startCapture) {
              return reject('VIDEO_NOT_READY')
            }
            startCapture()
            resolve()
          }),
        stopRecording: () =>
          new Promise<Blob>((resolve, reject) => {
            if (!stopCapture) {
              return reject('VIDEO_NOT_READY')
            }

            return stopCapture().then((blob) => {
              if (!blob) {
                return reject('NO_BLOB_RECORDED')
              }
              resolve(blob)
            })
          }),
      }),
      captureImage: createMultiCaptureBlurMachine(
        () =>
          new Promise<{ image: ImageData; blur: BlurDetectionResult }>(
            (resolve, reject) => {
              const image = getImageData(webcamRef.current)

              const video = webcamRef.current?.video
              const frame = document
                .getElementById('frame')
                ?.getBoundingClientRect()

              if (!image || !container || !video || !frame) {
                return reject('VIDEO_NOT_READY')
              }

              const rectangle = scalePlaceholderToVideo(container, video, frame)
              return waitForOpenCV()
                .then((cv) => {
                  const blur = blurValidation(cv, params, rectangle, image)
                  analytics.collectBlurResults(blur, params)
                  resolve({ image, blur })
                })
                .catch((err) => {
                  const openCvErr = handleCvExceptions(err)
                  console.error(openCvErr)
                  reject(err)
                })
            }
          )
      ),
    },
    actions: {
      submit: ({ image, video }) => {
        if (!image || !webcamRef.current) {
          return trackException('Missing photoPayload')
        }
        analytics.trackAutoCaptureSuccessOrManualFrameSelected()
        imageDataToBlob(image, 'image/jpeg', 1)
          .then((blob) => ({
            blob,
            sdkMetadata: getDeviceInfo(webcamRef.current?.stream),
          }))
          .then((imagePayload) => {
            const capturePayload = {
              ...appendFileName(imagePayload, side),
              isAutoCaptureUsed: analytics.getAutoCaptureSuccess(),
            }
            if (isMultiFrameCapture) {
              if (!video) {
                return trackException('Missing videoPayload')
              }

              const videoPayload = {
                blob: video,
                sdkMetadata: getDeviceInfo(webcamRef.current?.stream),
              }
              onMultiFrameCapture({
                photo: capturePayload,
                video: appendFileName(videoPayload, side),
              })
            } else {
              onCapture(capturePayload)
            }
          })
      },
      analyticsTimeout: analytics.collectTimeout,
      analyticsHoldStillPhaseEnded: analytics.collectHoldStillPhaseEnded,
      analyticsAutoCaptureSuccess: (ctx, data) =>
        analytics.collectAutoCaptureSuccess(data),
      analyticsSetDetectionError: analytics.collectSetDetectionError,
      analyticsManualCaptureStart: () => {
        analytics.collectManualCapture()
        analytics.trackManualShutterPressed()
      },
    },
  })

  useEffect(() => {
    waitForVideo.then(() => {
      send({ type: 'START' })
      analytics.setTrackCaptureStart(true)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [send, waitForVideo])

  useEffect(() => {
    // Uses renderFallback after 2 tries
    if (imageQualityRetries >= 2) {
      send({ type: 'ERROR' })
    }
  }, [send, imageQualityRetries])

  if (!webcamProps || !container) {
    return <Spinner />
  }

  const getStateValue = (v: StateValue) => {
    if (typeof v === 'string') {
      return v
    }
    return Object.keys(v)[0]
  }

  return (
    <Fragment>
      {enableDevtools && (
        <DevTools
          stateValue={value}
          edgeDetection={edgeDetection}
          params={params}
          onParams={setParams}
          video={webcamRef.current?.video}
          stream={webcamRef.current?.stream}
          frame={document.getElementById('frame')?.getBoundingClientRect()}
          onStart={() => send({ type: 'START', side })}
          onReset={() => send('RESET')}
        />
      )}
      {!enableTestPage && (
        <DocumentAutoOverlay
          side={side}
          documentType={documentType}
          context={context}
          value={getStateValue(value)}
          onCapture={() => send('CAPTURE')}
          renderFallback={renderFallback}
          trackScreen={trackScreen}
          documentAnalytics={documentAnalytics}
          isUploadFallbackDisabled={!!isUploadFallbackDisabled}
          isVideo={isMultiFrameCapture}
        >
          <Webcam
            {...webcamProps}
            className={style.webcam}
            width={container.width}
          />
        </DocumentAutoOverlay>
      )}
      {enableTestPage && <DocumentAutoTestPage />}
    </Fragment>
  )
}

const DocumentAuto = appendToTracking(
  withFailureHandling(withPermissionsFlow(DocumentAutoBase)),
  'jscamera'
)

export default DocumentAuto
