import React from 'react';
import PropType from 'prop-types';
import { useTranslation } from 'react-i18next';

const InputDropdown = ({ citizenship, countries, label, onChangeHandler }) => {
    const { t } = useTranslation();

  return (
    <div className="gov__form-group">
      <label>
        <p className="gov__label">{label}</p>

        <div className="gov__select-container">
          <select className={citizenship ? 'active' : ''} name="citizenship" onChange={onChangeHandler}>
            <option value="">{t('biometric-form.citizenship.placeholder')}</option>
            {countries.map((country, index) => <option key={index} defaultValue={''}
                                                       value={country.name}>{country.name}</option>)}
          </select>

          <svg role="presentation" className="gov__icon" width='23px' height='15px' viewBox='0 0 23 15' version='1.1' xmlns='http://www.w3.org/2000/svg'
               xmlnsXlink='http://www.w3.org/1999/xlink'><title>F8AC896A-E468-4DE2-BDB2-F60BEDC00FC9</title>
            <defs><polygon id='path-1' points='361.7025 36 370.5 45.2712551 379.2975 36 382 38.854251 370.5 51 359 38.854251'></polygon></defs>
            <g id='Page-1' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
              <g id='Help-section-Copy' transform='translate(-355.000000, -372.000000)'>
                <g id='Group-4' transform='translate(-4.000000, 336.000000)'>
                  <mask id='mask-2' fill='white'>
                    <use xlinkHref='#path-1'></use>
                  </mask>
                  <use id='Shape-Copy' fill='#8DDE43' fillRule='nonzero'
                       transform='translate(370.500000, 43.500000) rotate(-180.000000) translate(-370.500000, -43.500000) '
                       xlinkHref='#path-1'></use>
                </g>
              </g>
            </g>
          </svg>
        </div>
      </label>
    </div>
  );
};

InputDropdown.propTypes = {
    citizenship: PropType.string,
    countries: PropType.array,
    label: PropType.string,
    onChangeHandler: PropType.func,
};

InputDropdown.defaultProps = {
    citizenship: '',
    countries: [],
    label: '',
    onChangeHandler: null,
};

export default InputDropdown;
