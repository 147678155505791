const addSpaceIfNeeded = (char) => {
  if (char !== undefined && char !== ' ') {
    return ' ';
  }
  return '';
};

export const addSpacesToTitle = (text, separator) =>
  text
    .split('')
    .map((c, i) => {
      return c === separator
        ? addSpaceIfNeeded(text[i - 1]) +
            separator +
            addSpaceIfNeeded(text[i + 1])
        : c;
    })
    .join('');
