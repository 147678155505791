import React, { Fragment, useContext } from 'react';
import PropTypes from 'prop-types';
import { Trans, useTranslation } from 'react-i18next';

// Context
import UserContext from '../../state-management/context/UserContext';

// Components
import BiometricsCapture from '../biometric-form/BiometricsCapture';
import DocumentCapture from '../document-capture/DocumentCapture';
import DocumentSelection from '../document-selection/DocumentSelection';
import LandingPage from '../landing-page/LandingPage';
import Navbar from './Navbar';
import OnboardingSteps from '../onboarding/OnboardingSteps';
import Results from '../results/Results';
import TermsAndConditions from '../onboarding/TermsAndConditions';

// Consts
import stepIndex from '../../consts/stepIndex';
// import DateOfArrival from "../date-of-arrival/DateOfArrival";
import DynamicPrompts from '../dynamic-prompts/DynamicPrompts';
// import DocumentUpload from "../document-upload/DocumentUpload";

const RightToWorkContainer = () => {
    // Context variables
    const [userState] = useContext(UserContext);

    // Translation hook
    const { t } = useTranslation();

    return (
        <Fragment>
            <Navbar />
            <div className="gov__container">
                <Fragment>
                    { userState.onboardingIndex === stepIndex.LANDING_PAGE && <LandingPage /> }

                    {/*{userState.onboardingIndex === stepIndex.WHY_DO_I_NEED_THIS_STEP &&*/}
                    {/*  <DocumentUpload />*/}
                    {/*}*/}

                    {
                        userState.onboardingIndex === stepIndex.WHY_DO_I_NEED_THIS_STEP &&
                        <OnboardingSteps
                            animation="WHY_DO_I_NEED_THIS_ANIMATION"
                            animationClass="gov__why-do-i-need-this-icon"
                            description={
                                <Trans
                                    i18nKey="onboarding-steps.why-do-i-need-this.content"
                                    components={{ bold: <span className="bold" /> }}
                                />
                            }
                            step=""
                            title={t('onboarding-steps.why-do-i-need-this.title')}
                        />
                    }

                    {
                        userState.onboardingIndex === stepIndex.PERSONAL_DETAILS_STEP &&
                        <OnboardingSteps
                            animation="PERSONAL_DETAILS_ANIMATION"
                            animationClass="gov__personal-details-icon"
                            description={
                                <Trans
                                    i18nKey="onboarding-steps.personal-details.content"
                                    components={{ bold: <span className="bold" /> }}
                                />
                            }
                            step={t('shared.step-one')}
                            title={t('onboarding-steps.personal-details.title')}
                        />
                    }

                    {
                        userState.onboardingIndex === stepIndex.UPLOAD_DOCUMENTS_STEP &&
                        <OnboardingSteps
                            animation="UPLOAD_DOCUMENTS_ANIMATION"
                            animationClass="gov__upload-documents-icon"
                            description={
                                <Trans
                                    i18nKey="onboarding-steps.upload-documents.content"
                                    components={{ bold: <span className="bold" /> }}
                                />
                            }
                            step={t('shared.step-two')}
                            title={t('onboarding-steps.upload-documents.title')}
                        />
                    }

                    { userState.onboardingIndex === stepIndex.TERMS_AND_CONDITIONS_STEP && <TermsAndConditions /> }

                    { userState.onboardingIndex === stepIndex.BIOMETRICS_CAPTURE_STEP && <BiometricsCapture /> }

                    { userState.onboardingIndex === stepIndex.DYNAMIC_PROMPTS_STEP && <DynamicPrompts /> }

                    {/* { userState.onboardingIndex === stepIndex.DATE_OF_ARRIVAL_STEP && <DateOfArrival /> } */}

                    { userState.onboardingIndex === stepIndex.DOCUMENTS_SELECTION_STEP && <DocumentSelection /> }

                    { userState.onboardingIndex === stepIndex.DOCUMENT_CAPTURE_STEP && <DocumentCapture /> }

                    { userState.onboardingIndex === stepIndex.RESULTS_STEP && <Results /> }
                </Fragment>
            </div>
        </Fragment>
    );
};

RightToWorkContainer.propTypes = {
    match: PropTypes.object,
};

RightToWorkContainer.defaultProps = {
    match: {},
};

export default RightToWorkContainer;
