import React from 'react';
import PropTypes from 'prop-types';

const Button = ({ isDisabled, onClickHandler, text, classBtn }) => {
    return (
        <button
            className={`gov__btn ${classBtn}`}
            disabled={isDisabled}
            onClick={onClickHandler}
            type="button"
            aria-label={text}
        >
            {text}
        </button>
    );
};

Button.propTypes = {
    isDisabled: PropTypes.bool,
    onClickHandler: PropTypes.func,
    text: PropTypes.string,
    classBtn: PropTypes.string,
};

Button.defaultProps = {
    isDisabled: false,
    onClickHandler: null,
    text: 'Submit',
    classBtn: '',
};

export default Button;
