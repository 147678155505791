import React from 'react';
import PropTypes from 'prop-types';

const Modal = ({ children, className }) => {
    return (
        <div className={`gov__modal ${className}`}>
            <div className="gov__modal-container">
                {children}
            </div>
        </div>
    );
};

Modal.propTypes = {
    children: PropTypes.object,
    className: PropTypes.string,
};

Modal.defaultProps = {
    children: {},
    className: '',
};

export default Modal;
