import React, { Fragment, useContext } from 'react';
import QRCode from 'qrcode.react';
import { Trans, useTranslation } from 'react-i18next';

// Context
import UserContext from '../../state-management/context/UserContext';

// Components
import Navbar from '../shared/Navbar';

const QrCodeDetail = () => {
    // Context variables
    const [userState] = useContext(UserContext);

    // Translation hook
    const { t } = useTranslation();

    return (
        <Fragment>
            <Navbar />
            <div className="gov__qr-screen">
                <div className="gov__qr-container">
                    <h1>{t('landing-page.qr-code.title')}</h1>
                    <p>{t('landing-page.qr-code.subtitle')}</p>

                    <QRCode value={`${window.location.origin}/#/auth=${userState.uuid}`} size={200} />
                </div>

                <div className="gov__qr-subtext-container">
                    <p><Trans i18nKey="landing-page.qr-code.description.part-one" components={{ bold: <span /> }} /></p>
                    <p><Trans i18nKey="landing-page.qr-code.description.part-two" components={{ bold: <span /> }} /></p>
                </div>
            </div>
        </Fragment>
    );
};

export default QrCodeDetail;
