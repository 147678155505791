import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types'; // Corrected from 'PropType' to 'PropTypes'

import Modal from './Modal'; // Added import statement
// import Icon from '../shared/Icon';

const InputDate = ({
  error,
  label,
  onChangeHandler,
  selected,
  setIsModalOpen,
  isOpen,
}) => {
  const currentYear = new Date().getFullYear(); // Gets the current year
  const [selectedYear, setSelectedYear] = useState(currentYear);
  const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth()); // January is 0!
  const [selectedDay, setSelectedDay] = useState(new Date().getDate());

  const years = Array.from(
    { length: currentYear - 1900 + 1 },
    (_, i) => currentYear - i
  );
  // Months array for display
  const months = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  // Update day options based on selected month and year
  useEffect(() => {
    const daysInMonth = new Date(selectedYear, selectedMonth + 1, 0).getDate();
    if (selectedDay > daysInMonth) {
      setSelectedDay(daysInMonth);
    }
  }, [selectedYear, selectedMonth, selectedDay]);

  // Function to handle year selection
  const handleYearSelection = (year) => {
    setSelectedYear(year);
  };

  // Function to handle month selection
  const handleMonthSelection = (monthIndex) => {
    setSelectedMonth(monthIndex);
  };

  // Function to handle day selection
  const handleDaySelection = (day) => {
    setSelectedDay(day);
  };

  const handleConfirmBtn = () => {
    const date = `${String(selectedDay).padStart(2, '0')}/${String(selectedMonth + 1).padStart(2, '0')}/${selectedYear}`;
    onChangeHandler(date);
    setIsModalOpen(false);
  };

  const handleCancelBtn = () => {
    setIsModalOpen(false);
    onChangeHandler('');
  };

  return (
    <div className='gov__form-group'>
      {isOpen && (
        <Modal
          updateModal={(event) => setIsModalOpen(event)}
          className='gov_modal_date_picker_container'
        >
          <div className='gov__modal-date-picker'>
            <p className='gov__modal-date-picker_title'>
              Pick your date of birth
            </p>
            <div className='gov__wheel-picker-container'>
              <div className='gov__wheel-picker-ithem'>
                <span>Day</span>

                <div id='dayWheel' className='wheel day'>
                  {Array.from(
                    {
                      length: new Date(
                        selectedYear,
                        selectedMonth + 1,
                        0
                      ).getDate(),
                    },
                    (_, i) => i + 1
                  ).map((day) => (
                    <div
                      key={day}
                      className={`wheel-option ${
                        day === selectedDay ? 'selected' : ''
                      }`}
                      onClick={() => handleDaySelection(day)}
                    >
                      {day}
                    </div>
                  ))}
                </div>
              </div>
              <div className='gov__wheel-picker-ithem'>
                <span>Month</span>

                <div id='monthWheel' className='wheel month'>
                  {months.map((month, index) => (
                    <div
                      key={month}
                      className={`wheel-option ${
                        index === selectedMonth ? 'selected' : ''
                      }`}
                      onClick={() => handleMonthSelection(index)}
                    >
                      {month}
                    </div>
                  ))}
                </div>
              </div>
              <div className='gov__wheel-picker-ithem'>
                <span>Year</span>
                <div id='yearWheel' className='wheel year'>
                  {years.map((year) => (
                    <div
                      key={year}
                      className={`wheel-option ${
                        year === selectedYear ? 'selected' : ''
                      }`}
                      onClick={() => handleYearSelection(year)}
                    >
                      {year}
                    </div>
                  ))}
                </div>
              </div>

            </div>

            {/* confirm and cancel btn */}
            <div className='gov__modal-date-picker-btn-container'>
              <button
                className='gov__modal_btn gov__modal_btn_cancel'
                onClick={handleCancelBtn}
              >
                Cancel
              </button>
              <button
                className='gov__modal_btn gov__modal_btn_confirm'
                onClick={handleConfirmBtn}
              >
                Confirm
              </button>
            </div>
          </div>
        </Modal>
      )}
      <label>
        <p className='gov__label'>{label}</p>

        <input
          className='gov__date-input'
          type='text'
          readOnly
          placeholder='DD/MM/YYYY'
          value={selected}
          onClick={() => setIsModalOpen(true)}
        />

        {error && <p className='gov__form-error'>{error}</p>}
      </label>
    </div>
  );
};

InputDate.propTypes = {
  error: PropTypes.string,
  label: PropTypes.string,
  onChangeHandler: PropTypes.func,
  selected: new Date(), // Changed to expect a Date instance

  setIsModalOpen: PropTypes.func,
  isOpen: PropTypes.bool,
};

InputDate.defaultProps = {
  error: '',
  label: '',
  onChangeHandler: null,
  selected: '', // Default to current date if no value is provided
  setIsModalOpen: null,
  isOpen: false,
};

export default InputDate;
