import React, { useEffect, useState } from 'react';
import PropType from 'prop-types';
import { useTranslation } from 'react-i18next';

// Components
import Button from './Button';

const ContactForm = ({ errorMessage, onSubmit, subtext, title }) => {
    // Translation hook
    const { t } = useTranslation();

    // State variables
    const [text, setText] = useState('');

    useEffect(() => window.scroll(0, 0), [])

    return (
        <div className="gov__contact-form">
            <h1>{title}</h1>
            <p className="gov__contact-form-subtext">{subtext}</p>

            <textarea
                className="gov__textarea"
                maxLength="500"
                onChange={(event) => setText(event.target.value)}
                placeholder={t('shared.start-typing')}
                value={text}
            />

            <p className={`gov__text xxs ${text.length >= 499 ? 'red' : ' light grey'} gov__character-count`}>{t('shared.max-characters')}</p>

            { errorMessage && <p className="gov__form-error">{errorMessage}</p> }
            <Button isDisabled={!text} onClickHandler={() => onSubmit(text)} text={t('shared.send')} />
        </div>
    );
};

ContactForm.propTypes = {
    errorMessage: PropType.string,
    onSubmit: PropType.func,
    subtext: PropType.string,
    title: PropType.string,
};

ContactForm.defaultProps = {
    errorMessage: '',
    onSubmit: null,
    subtext: '',
    title: '',
};

export default ContactForm;
