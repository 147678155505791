import React, { Fragment, useContext, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import api from '../../consts/api';

// Context
import UserContext from '../../state-management/context/UserContext';
import {
  INCREMENT_INDEX,
  SET_LOADING,
} from '../../state-management/actions/actionTypes';

// Components
import Button from '../shared/Button';
import Modal from '../shared/Modal';

const TermsAndConditions = () => {
  // Context variables
  // eslint-disable-next-line no-unused-vars
  const [userState, userDispatch] = useContext(UserContext);
  const navigate = useNavigate();

  // Translation hook
  const { t } = useTranslation();

  // State variables
  const [isChecked, setIsChecked] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const checkForSanctionedCountry = async () => {
    userDispatch({ type: SET_LOADING, isLoading: true });

    // Call GeoIP2 Service to check if country is sanctioned
    api
      .get('/geolocation')
      .then((responseFromGeoIP2) => {
        if (responseFromGeoIP2.data === false) {
          userDispatch({ type: SET_LOADING, isLoading: false });
          userDispatch({ type: INCREMENT_INDEX });
        } else if (responseFromGeoIP2.data === '') {
          setLoading(false);
          throw 'Could not get ip address';
        } else {
          userDispatch({ type: SET_LOADING, isLoading: false });
          setLoading(false);
          navigate('/geoblocked');
        }
      })
      .catch((error) => {
        // Issue somewhere between client and GeoIP2 service - try using browser location
        console.log('Browser location flow', error);
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition((position) => {
            const latitude = position.coords.latitude;
            const longitude = position.coords.longitude;

            api
              .post(`/geolocation/maps`, {
                latitude: latitude,
                longitude: longitude,
              })
              .then((responseFromMapsAPI) => {
                if (responseFromMapsAPI.data === false) {
                  userDispatch({ type: SET_LOADING, isLoading: false });
                  userDispatch({ type: INCREMENT_INDEX });
                } else {
                  userDispatch({ type: SET_LOADING, isLoading: false });
                  navigate('/geoblocked');
                }
              })
              .catch((error) => {
                userDispatch({ type: SET_LOADING, isLoading: false });
                navigate('/geoblocked');
                console.log(error);
              });
          });
        } else {
          userDispatch({ type: SET_LOADING, isLoading: false });
          navigate('/geoblocked');
        }
      });
  };

  return (
    <Fragment>
      {isModalOpen && (
        <Modal updateModal={(event) => setIsModalOpen(event)}>
          <div className="gov__modal-header">
            <div className="gov__modal-banner">
              Deloitte<span>.</span>
            </div>
            <div
              className="gov__modal-close"
              role="button"
              aria-pressed="false"
              onClick={() => setIsModalOpen(false)}
            >
              &times;
            </div>
          </div>
          <div className="gov__modal-content">
            <p className="gov__modal-subtitle">{t('privacy-policy.title')}</p>
            <p className="gov__modal-subtitle">{t('privacy-policy.subtitle')}</p>
            <p>{t('privacy-policy.revisedAt')}</p>

            {/* <h1>Privacy Summary</h1> */}
            <p>
              {t('privacy-policy.paragraphs.1')}
            </p>
            <p>
              {t('privacy-policy.paragraphs.2')}
            </p>
            <p className="gov__bold">
              {t('privacy-policy.paragraphs.3')}
            </p>
            <p>
              {t('privacy-policy.paragraphs.4')}
            </p>
            <p className="gov__bold">
              {t('privacy-policy.paragraphs.5')}
            </p>
            <p>
              {t('privacy-policy.paragraphs.6')}
            </p>
            <p>
              {t('privacy-policy.paragraphs.7')}
            </p>
            <p>
              {t('privacy-policy.paragraphs.8')}
            </p>
            <p>
              {t('privacy-policy.paragraphs.9')}
            </p>
            <p>
              {t('privacy-policy.paragraphs.10')}
            </p>

            <p>
              {t('privacy-policy.paragraphs.11')}
            </p>
            <p>
              {t('privacy-policy.paragraphs.12')}
            </p>
            <p>
              {t('privacy-policy.paragraphs.13')}
            </p>
            <p>
              {t('privacy-policy.paragraphs.14')}
            </p>
            <p className="gov__bold">
              {t('privacy-policy.paragraphs.15')}
            </p>
            <p>
              {t('privacy-policy.paragraphs.16')}
            </p>
            <p>
              {t('privacy-policy.paragraphs.17')}
            </p>
            <p>
              {t('privacy-policy.paragraphs.18')}
            </p>
            <p className="gov__bold">
              {t('privacy-policy.paragraphs.19')}
            </p>

            <p>
              {t('privacy-policy.paragraphs.20')}
            </p>
            <p className="gov__bold">
              {t('privacy-policy.paragraphs.21')}
            </p>
            <p>
              {t('privacy-policy.paragraphs.22')}
            </p>
            <p>
              {t('privacy-policy.paragraphs.23')}
            </p>
            <p>
              {t('privacy-policy.paragraphs.24')}
            </p>
            <p>
              {t('privacy-policy.paragraphs.25')}
            </p>
            <p>
              {t('privacy-policy.paragraphs.26')}
            </p>
            <p>
              {t('privacy-policy.paragraphs.27')}
            </p>
            <p>
              {t('privacy-policy.paragraphs.28')}
            </p>
            <p className="gov__bold">
              {t('privacy-policy.paragraphs.29')}
            </p>

            <p>
              {t('privacy-policy.paragraphs.30')}
            </p>
            <p>
              {t('privacy-policy.paragraphs.31')}
            </p>
            <p>
              {t('privacy-policy.paragraphs.32')}
            </p>
            <p>
              {t('privacy-policy.paragraphs.33')}
            </p>
            <p>
              {t('privacy-policy.paragraphs.34')}
            </p>
            <p className="gov__bold">
              {t('privacy-policy.paragraphs.35')}
            </p>
            <p>
              {t('privacy-policy.paragraphs.36')}
            </p>
            <p>
              {t('privacy-policy.paragraphs.37')}
            </p>
            <p>
              {t('privacy-policy.paragraphs.38')}
            </p>
            <p>
              {t('privacy-policy.paragraphs.39')}
            </p>

            <p>
              {t('privacy-policy.paragraphs.40')}
            </p>
            <p>
              {t('privacy-policy.paragraphs.41')}
            </p>
            <p className="gov__bold">
              {t('privacy-policy.paragraphs.42')}
            </p>
            <p>
              {t('privacy-policy.paragraphs.43')}
            </p>
            <p>
              {t('privacy-policy.paragraphs.44')}
            </p>
            <p>
              {t('privacy-policy.paragraphs.45')}
            </p>
            <p>
              {t('privacy-policy.paragraphs.46')}
            </p>
            <p className="gov__bold">
              {t('privacy-policy.paragraphs.47')}
            </p>
            <p>
              {t('privacy-policy.paragraphs.48')}
            </p>
            <p>
              {t('privacy-policy.paragraphs.49')}
            </p>

            <p>
              {t('privacy-policy.paragraphs.50')}
            </p>
            <p>
              {t('privacy-policy.paragraphs.51')}
            </p>
            <p>
              {t('privacy-policy.paragraphs.52')}
            </p>
            <p>
              {t('privacy-policy.paragraphs.53')}
            </p>
            <p>
              {t('privacy-policy.paragraphs.54')}
            </p>
            <p>
              {t('privacy-policy.paragraphs.55')}
            </p>
            <p>
              {t('privacy-policy.paragraphs.56')}
            </p>
            <p className="gov__bold">
              {t('privacy-policy.paragraphs.57')}
            </p>
            <p>
              {t('privacy-policy.paragraphs.58')}
            </p>
            <p>
              {t('privacy-policy.paragraphs.59')}
            </p>

            <p>
              {t('privacy-policy.paragraphs.60')}
            </p>
            <p>
              {t('privacy-policy.paragraphs.61')}
            </p>
            <p>
              {t('privacy-policy.paragraphs.62')}
            </p>
            <p>
              {t('privacy-policy.paragraphs.63')}
            </p>
            <p>
              {t('privacy-policy.paragraphs.64')}
            </p>
            <p>
              {t('privacy-policy.paragraphs.65')}
            </p>
          </div>
        </Modal>
      )}

      <div className="gov__terms-and-conditions-container">
        <div className="gov__terms-and-conditions-title">
          <h1>{t('terms-and-conditions.title')}</h1>

          <p>
            <Trans
              i18nKey="terms-and-conditions.subtitle"
              components={{
                underline: (
                  <span
                    role="button"
                    aria-pressed="false"
                    onClick={() => setIsModalOpen(true)}
                  />
                ),
              }}
            />
          </p>
        </div>

        <div className="gov__divider"></div>

        <div className="gov__terms-and-conditions-biometric-section">
          <p className="gov__biometric-title">
            {t('terms-and-conditions.biometric-info.title')}
          </p>
          <div className="gov__terms-and-conditions-subtext">
            <p>
              <Trans i18nKey="terms-and-conditions.biometric-info.content" />
            </p>
          </div>
        </div>

        <div className="gov__terms-and-conditions-footer">
          <div>
            <div className="gov__consent-text">
              <p>
                <Trans
                  i18nKey="terms-and-conditions.consent-information"
                  components={{
                    a: (
                      <a
                        href="https://onfido.com/facial-scan-policy-and-release/"
                        target="_blank"
                        rel="noreferrer"
                      />
                    ),
                    italic: <i />,
                    ins: <ins />,
                  }}
                />
              </p>
            </div>
            <div className="gov__checkbox-container">
              <label className="gov__checkbox-label">
                <input type="checkbox" />
                <span
                  className="gov__checkmark"
                  role="button"
                  aria-pressed="false"
                  aria-label="Checkmark icon"
                  onClick={() => setIsChecked(!isChecked)}
                />
              </label>
            </div>
          </div>

          <Button
            isDisabled={!isChecked || loading}
            onClickHandler={checkForSanctionedCountry}
            text={t('terms-and-conditions.accept-and-start')}
          />
        </div>
      </div>
    </Fragment>
  );
};

export default TermsAndConditions;
