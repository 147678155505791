import React from 'react';
import PropType from 'prop-types';

const Icon  = ({ icon, label, onClickHandler }) => <div className={icon} role="button" aria-pressed="false" aria-label={label} onClick={onClickHandler} />;

Icon.propTypes = {
    icon: PropType.string,
    label: PropType.string,
    onClickHandler: PropType.func,
};

Icon.defaultProps = {
    icon: '',
    label: '',
    onClickHandler: null,
};

export default Icon;
