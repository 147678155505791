import React, { Fragment } from "react";
import { useTranslation, Trans } from "react-i18next";
import Animation from "../animations/Animation";

const GeoBlocked = () => {
  // Translation hook
  const { t } = useTranslation();

  return (
    <Fragment>
      <div className="gov__landing-page-container">
        <Animation
          animationName="LANDING_PAGE_ANIMATION"
          elementName="gov__landing-page-icon"
          alt={t("Logo")}
        />
        <div
          className="gov__landing-page-information"
          style={{
            marginLeft: "40px",
            marginRight: "40px",
          }}
        >
          <h1>
            <Trans
              i18nKey="landing-page.welcome"
              components={{ wrap: <span className="gov__rtw-message" /> }}
            />
          </h1>

          <div
            className="text-container"
            style={{
              fontSize: "0.75rem",
            }}
          >
            <p style={{ fontSize: "1.25rem" }}>
              {t("landing-page.geoblocked")}
            </p>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default GeoBlocked;
