import approvedResultAnimationData from './approved-result-animation.json';
import landingPageAnimationData from './landing-page-animation.json';
import loadingAnimationData from './loading-animation.json';
import manualCheckAnimationData from './manual-check-animation.json';
import personalDetailsAnimationData from './personal-details-animation.json';
import uploadDocumentsAnimationData from './upload-documents-icon.json';
import warningTimeoutAnimationData from './warning-timeout-animation.json';
import whyDoINeedThisAnimationData from './why-do-i-need-this-animation.json';

export default {
    LANDING_PAGE_ANIMATION: landingPageAnimationData,
    LOADING_ANIMATION: loadingAnimationData,
    WHY_DO_I_NEED_THIS_ANIMATION: whyDoINeedThisAnimationData,
    PERSONAL_DETAILS_ANIMATION: personalDetailsAnimationData,
    UPLOAD_DOCUMENTS_ANIMATION: uploadDocumentsAnimationData,
    APPROVED_RESULT_ANIMATION: approvedResultAnimationData,
    RESULT_MANUAL_CHECK: manualCheckAnimationData,
    WARNING_TIMEOUT_ANIMATION: warningTimeoutAnimationData,
};
