import React, {Fragment} from 'react';
import PropType from 'prop-types';
import {Trans, useTranslation} from 'react-i18next';
import Icon from "../shared/Icon";

const DocumentSelectionHeader = ({isDocumentPrivacyRequired}) => {
  const {t} = useTranslation();

  return (
    <Fragment>
      <p className="gov__document-combination-step-title">{t('shared.step-two')}</p>

      {
        isDocumentPrivacyRequired ? (
          <Fragment>
            <p className="gov__document-selection-content">{t('document-selection.privacy.content-part-one')}</p>
            <p className="gov__document-selection-content">{t('document-selection.privacy.content-part-two')}</p>
          </Fragment>
        ) : (
          <div className="gov__document-selection-content">
            <p>{t('document-selection.title')}</p>

            <Trans
              className="gov__document-selection-content"
              i18nKey="document-selection.sub-title"
              components={{icon: <Icon icon="gov__document-combo-permanent" label="Long term right to work icon"/>}}
            />
          </div>
        )
      }
    </Fragment>
  );
};

DocumentSelectionHeader.propTypes = {
  isDocumentPrivacyRequired: PropType.bool,
};

DocumentSelectionHeader.defaultProps = {
  isDocumentPrivacyRequired: false,
};

export default DocumentSelectionHeader;