import React, { useEffect } from 'react';
import lottie from 'lottie-web';
import PropType from 'prop-types';
import animations from './animationData';

const Animation = ({ animationName, elementName }) => {
    useEffect(() => {
        lottie.loadAnimation({
            container: document.getElementsByClassName(elementName)[0],
            renderer: 'svg',
            loop: true,
            autoplay: true,
            animationData: animations[animationName],
        });

        // eslint-disable-next-line
    }, []);

    return (
        <div className={elementName} />
    );
};

Animation.propTypes = {
    animationName: PropType.string,
    elementName: PropType.string,
};

Animation.defaultProps = {
    animationName: '',
    elementName: '',
};

export default Animation;
