import logo from '../../assets/logo.jpg';
import { useState } from 'react';

export default function logoChecker(){

    const [isLogoExist, setIsLogoExist] = useState(false);

    const img = new Image();
    img.src = logo;

    img.onload = () => {
        img.width < 10 ? setIsLogoExist(false) : setIsLogoExist(true);
    };
    img.onerror = (err) => {
        console.log("img error");
        console.error(err);
    };
    return isLogoExist;
}
