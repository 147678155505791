import React, { Fragment, useState, useContext } from 'react';
import Button from '../shared/Button';
import api from '../../consts/api';
import { SET_LOADING } from '../../state-management/actions/actionTypes';
import UserContext from '../../state-management/context/UserContext';
import PropTypes from 'prop-types';
import imageCompression from 'browser-image-compression';
import DocumentUploadInput from './DocumentUploadInput';
import DocumentRemoveInput from './DocumentRemoveInput';
import { useTranslation } from "react-i18next";

const COMPRESSION_OPTIONS = {
  maxSizeMB: 0.2,
  maxWidthOrHeight: undefined,
  useWebWorker: true,
};

const compress = (uncompressedFile) => {
  return new Promise((resolve, reject) => {
    imageCompression(uncompressedFile, COMPRESSION_OPTIONS)
      .then((compressedFile) => {
        const reader = new FileReader();
        reader.onloadend = function () {
          if (compressedFile) {
            resolve(reader.result.split(',')[1]);
          }
        };
        reader.readAsDataURL(compressedFile);
      })
      .catch((e) => reject(e));
  });
};

// Construct custom document sides list
const createDocumentSidesList = (document) => {
  return [...Array(document.sides).keys()].map(documentSideIndex => {
    const side = document.sides === 1 ? "front" : document.sides === 2 && documentSideIndex === 0 ? "front" : document.sides === 2 && documentSideIndex === 1 ? "back" : documentSideIndex
    const buttonText = (document.sides === 1 ? "choose file" : document.sides === 2 ? `choose ${side} side` : `choose page ${documentSideIndex}`).toUpperCase()

    return {
      sideId: documentSideIndex,
      compressedImage: null,
      fileName: null,
      file: null,
      buttonText,
      data: {
        side,
        rulesEngineId: document.id,
        image: null,

        // should not be sent (can be filled by server)
        supported: false,
      }
    }
  })
}

const createPayload = (documents) => documents.map(doc => ({ ...doc.data, image: doc.compressedImage }));

const DocumentUpload = ({ document, updateDocumentIndex }) => {
  // eslint-disable-next-line no-unused-vars
  const [userState, userDispatch] = useContext(UserContext);
  const [documentSidesList, setDocumentSidesList] = useState(createDocumentSidesList(document));
  const [processing, setProcessing] = useState(false);
  const [errorSides, setErrorSides] = useState([]);
  const [uploadError, setUploadError] = useState(false);
  const { t } = useTranslation();

  const handleCapture = async (event, sideId) => {
    const file = event.target.files[0];
    if (file) {
      setDocumentSidesList(documentSidesList.map((docSide) => {
        if (sideId === docSide.sideId) {
          docSide.fileName = file.name;
          docSide.file = file
        }
        return docSide;
      }));
    }

    event.target.value = null;
  };

  const translateText = (textToTranslate) => {
    const translateLowerCase = textToTranslate.toLowerCase();

    if (translateLowerCase === 'choose file') {
      return t('unsupported-document.choose-file')
    }
    else if (translateLowerCase === 'choose front side') {
      return t('unsupported-document.choose-front-side')
    }
    else if (translateLowerCase === 'choose back side') {
      return t('unsupported-document.choose-back-side')
    }
    else if (translateLowerCase === 'front side') {
      return t('unsupported-document.front-side')
    }
    else if (translateLowerCase === 'back side') {
      return t('unsupported-document.back-side')
    }
    else {
      return textToTranslate;
    }
  }

  const handleUpload = async () => {
    setErrorSides([]);
    setProcessing(true);
    setUploadError(false);

    let tempDocumentSidesList = [...documentSidesList];
    const errors = [];

    for (const documentSide of tempDocumentSidesList) {
      try {
        documentSide.compressedImage = await compress(documentSide.file);
      } catch (e) {
        console.log(e);
        // text pushed in this array will appear in the error list

        // 
        errors.push(translateText((documentSide.data.side)).toUpperCase());
        documentSide.fileName = null;
        documentSide.file = null;
        documentSide.compressedImage = null;
      }
    }

    if (tempDocumentSidesList.some(doc => !doc.compressedImage?.length)) {
      setDocumentSidesList(tempDocumentSidesList);
      setErrorSides(errors);
      setProcessing(false);
    } else {
      userDispatch({ type: SET_LOADING, isLoading: true });

      api.post('new-hires/unsupported-document', {
        applicantId: '',
        documents: createPayload(tempDocumentSidesList),
      }, {
        headers: {
          'Content-Type': 'application/json',
        }
      }).then((response) => {
        // TODO: Only upload if successful, currently if response === true - this should be changed to response.status === 201
        console.log(response.status);
        updateDocumentIndex();
        if (userState.documentIndex < userState.selectedDocumentCombination.documents.length - 1) {
          userDispatch({ type: SET_LOADING, isLoading: false });
        }
      }).catch((error) => {
        console.log(error);
        userDispatch({ type: SET_LOADING, isLoading: false });
        setProcessing(false);
        setUploadError(true);
      });
    }
  };

  const handleRemoveFile = (sideId) => {
    setDocumentSidesList(documentSidesList.map((docSide) => {
      if (sideId === docSide.sideId) {
        docSide.fileName = null;
        docSide.file = null
      }
      return docSide;
    }));
  };

  return (
    <Fragment>
      <div className="gov__document-upload-container">
        <div className="gov__document-upload-content">
          <h1 className="gov__document-upload-title">{document.name}</h1>
          <p className="gov__document-upload-description">
            {document.description}
          </p>
          <div className="gov__document-upload-central-container">
            <div className="onfido-sdk-ui-Theme-icon onfido-sdk-ui-Uploader-identityIcon img-div"></div>
            <div>
              {documentSidesList.filter(docSide => docSide.compressedImage == null || docSide.compressedImage == '').map((docSide, i) => {
                return <DocumentUploadInput key={`side-${i}`} documentObject={docSide} title={translateText(docSide.buttonText).toUpperCase()} sideId={docSide.sideId} handleCapture={handleCapture} />
              })}
              <p
                className="gov__document-upload-note
              "
              >
                {t('unsupported-document.picture-format')}
              </p>
            </div>

            {documentSidesList.filter(docSide => docSide.fileName != null && [null, undefined, ''].indexOf(docSide.compressedImage) !== -1).map((docSide, i) => {
              return <DocumentRemoveInput key={`file-${i}`} sideId={docSide.sideId} handleRemoveFile={handleRemoveFile} fileName={docSide.fileName} />
            })}
          </div>

          {!!errorSides.length && <div className='process-error'>
            <p>
              {t('unsupported-document.error-file-specific')}
            </p>
            <ul>
              {errorSides.map((es, i) => {
                return <li key={`err-${i}`}>{es}</li>
              })}
            </ul>
            <p>
              {t('unsupported-document.re-submit')}
            </p>
          </div>}

          {uploadError && <div className='process-error'>
            <p>
              {t('unsupported-document.error-generic')}
            </p>
            <p>
              {t('unsupported-document.re-submit')}
            </p>
          </div>}

          <div className="gov__document-upload-footer">
            {processing ? <div className="gov__btn gov__document-input-loading">
              <div className="gov__loading-container">
                <div className={'gov__loading-spinner-container'}>
                  <div></div>
                </div>
              </div>
            </div> :
              <Button
                classBtn="gov__document-upload-action-button"
                onClickHandler={handleUpload}
                text={processing ? t('unsupported-document.wait') : t('unsupported-document.submit')}
                isDisabled={documentSidesList.some(docSide => !docSide.file) || processing}
              />}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

DocumentUpload.propTypes = {
  document: PropTypes.object,
  updateDocumentIndex: PropTypes.func,
};

export default DocumentUpload;
