import { DocumentSides } from '~types/commons'
import { DocumentTypes } from '~types/steps'

const getPositionPhrase = (
  side: DocumentSides,
  documentType: DocumentTypes
): string => {
  if (documentType === 'passport') {
    return 'doc_auto_capture.footer.position_passport'
  }
  if (side === 'front') {
    switch (documentType) {
      case 'driving_licence':
        return 'doc_auto_capture.footer.position_license_front'
      case 'national_identity_card':
        return 'doc_auto_capture.footer.position_identity_card_front'
      case 'residence_permit':
        return 'doc_auto_capture.footer.position_residence_permit_front'
      case 'generic_document':
        return 'doc_auto_capture.footer.position_generic_document_front'
    }
  }
  switch (documentType) {
    case 'driving_licence':
      return 'doc_auto_capture.footer.position_license_back'
    case 'national_identity_card':
      return 'doc_auto_capture.footer.position_identity_card_back'
    case 'residence_permit':
      return 'doc_auto_capture.footer.position_residence_permit_back'
    case 'generic_document':
      return 'doc_auto_capture.footer.position_generic_document_back'
  }
}

const getManualFallbackPhrase = (
  side: DocumentSides,
  documentType: DocumentTypes
): string => {
  if (documentType === 'passport') {
    return 'doc_auto_capture.footer.manual_fallback_passport'
  }
  if (side === 'front') {
    switch (documentType) {
      case 'driving_licence':
        return 'doc_auto_capture.footer.manual_fallback_license_front'
      case 'national_identity_card':
        return 'doc_auto_capture.footer.manual_fallback_identity_card_front'
      case 'residence_permit':
        return 'doc_auto_capture.footer.manual_fallback_residence_permit_front'
      case 'generic_document':
        return 'doc_auto_capture.footer.manual_fallback_generic_document_front'
    }
  }
  switch (documentType) {
    case 'driving_licence':
      return 'doc_auto_capture.footer.manual_fallback_license_back'
    case 'national_identity_card':
      return 'doc_auto_capture.footer.manual_fallback_identity_card_back'
    case 'residence_permit':
      return 'doc_auto_capture.footer.manual_fallback_residence_permit_back'
    case 'generic_document':
      return 'doc_auto_capture.footer.manual_fallback_generic_document_back'
  }
}

const getLocalisationStrings = (
  side: DocumentSides,
  documentType: DocumentTypes,
  isVideo: boolean
) => {
  return {
    holdStill: 'doc_auto_capture.frame.hold_still',
    flipDocument: 'doc_auto_capture.frame.flip_document',
    noDocument: 'doc_auto_capture.frame.no_document',
    capturing: isVideo
      ? 'doc_capture.header.recording_video'
      : 'doc_auto_capture.footer.capturing',
    captured: isVideo
      ? 'doc_capture.header.recording_complete'
      : 'doc_auto_capture.footer.captured',
    positionDocument: getPositionPhrase(side, documentType),
    fallbackTakePhoto: getManualFallbackPhrase(side, documentType),
  }
}

export { getLocalisationStrings }
