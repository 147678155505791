import {
    SELECT_ARTICLE,
    SELECT_VIEW,
    RESET_SELECTED_ARTICLE,
    UPDATE_SEARCH_TEXT,
    UPDATE_SUCCESS_SCREEN,
} from '../actions/actionTypes';

const HelpSectionReducer = (state, action) => {
    switch (action.type) {
        case SELECT_ARTICLE:
            return {
                ...state,
                selectedArticle: action.article,
                searchText: action.text,
            };
        case SELECT_VIEW:
            return {
                ...state,
                currentView: action.currentView,
                searchText: action.searchText,
            };
        case RESET_SELECTED_ARTICLE:
            return {
                ...state,
                selectedArticle: null,
            };
        case UPDATE_SEARCH_TEXT:
            return {
                ...state,
                filteredArticles: action.filteredArticles,
                searchText: action.text,
            };
        case UPDATE_SUCCESS_SCREEN:
            return {
                ...state,
                isContactUsResultVisible: action.isContactUsResultVisible,
            };
        default:
            return state;
    }
};

export default HelpSectionReducer;
