import React, {useContext} from 'react';
import PropTypes from 'prop-types';

// Context
import UserContext from '../../state-management/context/UserContext';
import {INCREMENT_INDEX} from '../../state-management/actions/actionTypes';

// Components
import Animation from '../animations/Animation';
import Icon from '../shared/Icon';

const OnboardingSteps = ({animation, animationClass, description, step, title}) => {
  // eslint-disable-next-line no-unused-vars
  const [userState, userDispatch] = useContext(UserContext);

  return (
    <div className="gov__onboarding-container">
      <Animation animationName={animation} elementName={animationClass}/>

      <div className="gov__onboarding-title">
        {step && <p>{step}</p>}
        <h1>{title}</h1>
      </div>

      <p className="gov__onboarding-description">
        {description}
      </p>

      <div className="gov__swipe-container">
        <button className="gov__btn circle" type="button" onClick={() => userDispatch({type: INCREMENT_INDEX})}>
          <Icon icon="gov__arrow-right" label="Next page"/>
        </button>
      </div>
    </div>
  );
};

OnboardingSteps.propTypes = {
  animation: PropTypes.string,
  animationClass: PropTypes.string,
  description: PropTypes.element,
  step: PropTypes.string,
  title: PropTypes.string,
};

OnboardingSteps.defaultProps = {
  animation: {},
  animationClass: '',
  description: <span/>,
  step: '',
  title: '',
};

export default OnboardingSteps;
