import React, { Fragment, useContext } from 'react';
import PropType from 'prop-types';
import { useTranslation } from 'react-i18next';

// Context
import HelpSectionContext from '../../state-management/context/HelpSectionContext';
import { SELECT_VIEW } from '../../state-management/actions/actionTypes';

// Components
import HelpSectionArticle from './HelpSectionArticle';
import Icon from '../shared/Icon';

// Consts
import helpSectionViews from '../../consts/helpSectionViews';

const HelpSectionArticleList = ({ iconClass, title, type }) => {
    // Context variables
    const [helpSectionState, helpSectionDispatch] = useContext(HelpSectionContext);

    // Translation hook
    const { t } = useTranslation();

    return (
        <div className="gov__article-list">
            <p className="gov__topic-title">{title}</p>

            {
                type.map((article, index) => 
                    <Fragment key={index}>
                        <HelpSectionArticle
                            description={article.description}
                            iconClass={iconClass}
                            subtext={article.subtext}
                            title={article.title}
                        />
                    </Fragment>
                )
            }

            {
                helpSectionState.currentView !== helpSectionViews.HELP_CENTER &&
                <div className="gov__help-section-read-more">
                    <Icon
                        icon="gov__chevron left help-section"
                        label="Arrow left"
                        onClickHandler={() => helpSectionDispatch({ type: SELECT_VIEW, currentView: helpSectionViews.HELP_CENTER, searchText: '' })}
                    />
                    <div onClick={() => helpSectionDispatch({ type: SELECT_VIEW, currentView: helpSectionViews.HELP_CENTER, searchText: '' })}>
                        <span className="gov__link">{t('help.back')}</span>
                    </div>
                </div>
            }
        </div>
    );
};

HelpSectionArticleList.propTypes = {
    iconClass: PropType.string,
    title: PropType.string,
    type: PropType.array,
};

HelpSectionArticleList.defaultProps = {
    iconClass: '',
    title: '',
    type: [],
};

export default HelpSectionArticleList;
