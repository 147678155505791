import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

// Context
import UserContext from '../../../state-management/context/UserContext';

// Components
import LanguageRow from './LanguageRow';

// Const
import { SET_SIDEBAR } from '../../../state-management/actions/actionTypes';

const Sidebar = () => {
    // Context variables
    const [userState, userDispatch] = useContext(UserContext);

    // Translation hook
    const { t } = useTranslation();

    return (
        <div className={`gov__sidebar ${userState.sidebarStatus}`}>
            <div className="gov__sidebar-header" role="button" aria-pressed="false" onClick={() => userDispatch({ type: SET_SIDEBAR, sidebarStatus: 'hidden' })}>
                &times;
            </div>

            <p className="gov__step-title">{t('landing-page.sidebar.languages.title')}</p>
            <LanguageRow iconClass="gov__language-icon en" isoCode="en" languageDescription={t('landing-page.sidebar.languages.en')} />
            <LanguageRow iconClass="gov__language-icon nl" isoCode="nl" languageDescription={t('landing-page.sidebar.languages.nl')} />
            <LanguageRow iconClass="gov__language-icon de" isoCode="de" languageDescription={t('landing-page.sidebar.languages.de')} />
            <LanguageRow iconClass="gov__language-icon pl" isoCode="pl" languageDescription={t('landing-page.sidebar.languages.pl')} />
            <LanguageRow iconClass="gov__language-icon fr" isoCode="fr" languageDescription={t('landing-page.sidebar.languages.fr')} />
            <LanguageRow iconClass="gov__language-icon es" isoCode="es" languageDescription={t('landing-page.sidebar.languages.es')} />
        </div>
    )
};

export default Sidebar;
