import React from "react";
import PropTypes from 'prop-types';

const DocumentRemoveInput = ({ sideId, handleRemoveFile, fileName }) => {
    return (

        <div className="gov__document-upload-files-main">
            <div className="gov__document-upload-files-container">
                <button
                    id="gov__document-upload-remove-button"
                    onClick={(e) => {
                        e.preventDefault();
                        handleRemoveFile(sideId);
                    }}
                    className="img-div"
                >
                    {'\u274c'}
                </button>
                <div
                    id="gov__document-upload-files-icon"
                    className="img-div"
                ></div>
                <div id="gov__document-upload-file-name">
                    {fileName}
                </div>
            </div>
        </div>
    )

}

DocumentRemoveInput.propTypes = {
    fileName: PropTypes.string,
    sideId: PropTypes.number,
    handleRemoveFile: PropTypes.func,
};


export default DocumentRemoveInput;