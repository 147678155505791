import React, { useContext } from 'react';

// Context
import UserContext from '../../state-management/context/UserContext';

const LoadingSpinner = () => {
    const [userState] = useContext(UserContext);

    return (
      <div className="gov__loading-container">
          <div className={userState.isLoading ? 'gov__loading-spinner-container' : ''}>
            <div></div>
          </div>

        {userState.loadingMessage !== '' &&
          <div className="gov__loading-message-container">
            <p>{userState.loadingMessage}</p>
          </div>
        }
      </div>
    );
};

export default LoadingSpinner;
