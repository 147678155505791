import React, { Fragment, useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import PropType from 'prop-types';
import { useTranslation } from 'react-i18next';

// Context
import HelpSectionContext from '../../state-management/context/HelpSectionContext';
import UserContext from '../../state-management/context/UserContext';
import {
    DECREMENT_INDEX,
    DECREMENT_SKIP_PAGE,
    RESET_SELECTED_ARTICLE,
    SELECT_VIEW,
    SET_LOADING,
    SET_NAVIGATION_BLOCK,
    SET_NO_DOCUMENTS_AVAILABLE,
    SET_REPORT_ERRORS,
    SET_SIDEBAR,
    UPDATE_DOCUMENT_INDEX,
    UPDATE_SUCCESS_SCREEN,
    UPDATE_VENDOR_DETAILS,
    // DELETE_ONFIDO_DATA
} from '../../state-management/actions/actionTypes'

// Components
import Animation from '../animations/Animation';
import Button from './Button';
import Icon from './Icon';
import Modal from './Modal';

// Consts
import helpSectionViews from '../../consts/helpSectionViews';
import stepIndex from '../../consts/stepIndex';
import api from "../../consts/api";
import logo from '../../assets/logo.jpg';
import logoChecker from './logoChecker';

const Navbar = () => {
    // Context variables
    const [helpSectionState, helpSectionDispatch] = useContext(HelpSectionContext);
    const [userState, userDispatch] = useContext(UserContext);

    // Translation hook
    const { t } = useTranslation();

    // State variables
    const [isModalOpen, setIsModalOpen] = useState(false);

    // Check the type of device the user has
    const isMobileDevice = () => /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);

    const resetAndDeleteDocumentData = () => {
        userDispatch({ type: SET_LOADING, isLoading: true });
        api.delete('/new-hires/delete').then(async response => {
            const updatedVendor = {
                id: response.data.id,
                token: response.data.token,
                dateRanges: userState.vendor.dateRanges,
                needsDateFilter: userState.vendor.needsDateFilter
            }
            userDispatch({ type: UPDATE_DOCUMENT_INDEX, documentIndex: 0})
            userDispatch({ type: UPDATE_VENDOR_DETAILS, vendor: updatedVendor })
            userDispatch({ type: DECREMENT_INDEX })
            userDispatch({ type: SET_LOADING, isLoading: false });
        }).catch(error => {
            console.log('error', error)
        });
    }

    const renderArrow = () => {
        // Document Capture Component (Onfido)
        if (userState.onboardingIndex === stepIndex.DOCUMENT_CAPTURE_STEP) {
            return (
                <Icon
                    icon="gov__chevron left"
                    label="Go back - delete onfido"
                    onClickHandler={() => resetAndDeleteDocumentData()}
                />
            );
        }

        // Check if we are in the help section
        if (window.location.hash.indexOf('help-section') > -1) {
            if (helpSectionState.isContactUsResultVisible) {
                return (
                    <Icon
                        icon="gov__chevron left"
                        label="Go back"
                        onClickHandler={() => helpSectionDispatch({ type: UPDATE_SUCCESS_SCREEN, isContactUsResultVisible: false })}
                    />
                );
            }

            if (helpSectionState.selectedArticle) {
                return (
                    <Icon
                        icon="gov__chevron left"
                        label="Go back"
                        onClickHandler={() => helpSectionDispatch({ type: RESET_SELECTED_ARTICLE })}
                    />
                );
            }

            if (helpSectionState.currentView !== helpSectionViews.HELP_CENTER) {
                return (
                    <Icon
                        icon="gov__chevron left"
                        label="Go back"
                        onClickHandler={() => helpSectionDispatch({ type: SELECT_VIEW, currentView: helpSectionViews.HELP_CENTER })}
                    />
                );
            }

            return null;
        }

        // Incorrect data will go to landing page or set a state depending on check box section or text input
        if (window.location.hash.indexOf('incorrect-data') > -1) {
            if (userState.reportErrors) {
                return (
                    <Icon
                        icon="gov__chevron left"
                        label="Go back"
                        onClickHandler={() => userDispatch({type: SET_REPORT_ERRORS, reportErrors: false})  }
                    />
                );
            } else {
                return (
                    <Link to="/">
                        <Icon icon="gov__chevron left" label="Go back" />
                    </Link>
                );
            }
        }

        // QR code goes back to the landing page
        if (window.location.hash.indexOf('qr-code') > -1) {
            return (
                <Link to="/">
                    <Icon icon="gov__chevron left" label="Go back" />
                </Link>
            );
        }

        // If on the landing page or the results screen, show no arrow
        if (userState.onboardingIndex <= stepIndex.LANDING_PAGE || userState.onboardingIndex >= stepIndex.RESULTS_STEP) {
            return null;
        }

        // If we are on a page that will result in losing progress, show the  modal
        if (userState.isNavigationBlocked) {
            return (
                <Icon
                    icon="gov__chevron left"
                    label="Go back"
                    onClickHandler={() => setIsModalOpen(true)}
                />
            );
        }

        // Skip the date-of-arrival component when it is not required but not when in no document flow
        if (!userState.vendor.needsDateFilter && !userState.isNoDocumentsAvailableFlow && userState.onboardingIndex === stepIndex.DOCUMENTS_SELECTION_STEP) {
            return (
              <Icon
                icon="gov__chevron left"
                label="Go back"
                onClickHandler={() => userDispatch({ type: DECREMENT_SKIP_PAGE})}
              />
            );
        }


        // If we are on the "I cannot provide any documents" contact form, go back to the document combinations selection screen
        if (userState.isNoDocumentsAvailableFlow) {
            return (
                <Icon
                    icon="gov__chevron left"
                    label="Go back"
                    onClickHandler={() => userDispatch({ type: SET_NO_DOCUMENTS_AVAILABLE, isNoDocumentsAvailableFlow: false })}
                />
            );
        }

        return (
            <Icon
                icon="gov__chevron left"
                label="Go back"
                onClickHandler={() => userDispatch({ type: DECREMENT_INDEX })}
            />
        );
    };

    const handleModalClick = () => {
        setIsModalOpen(false);
        userDispatch({ type: SET_NAVIGATION_BLOCK, isNavigationBlocked: false });
        userDispatch({ type: DECREMENT_INDEX });
    };

    const renderHelpIcon =() =>{
        //if on the help-section screen show the activated help icon
        if (window.location.hash.indexOf('help-section') > -1) {
        return (
            <Icon icon="gov__help-icon-activated" label="Help icon active" />
        );
        }
        //if not on mobile devices, and not having logo, show normal help icon
        if (!logoChecker()){
            if(userState.onboardingIndex > stepIndex.LANDING_PAGE) {
                return (
                    <Icon
                        icon="gov__help-icon"
                        label="Help icon"
                        style="margin-left:auto"
                        onClickHandler={() => window.open(`${window.location.origin}/#/help-section`)}
                    />
                );
            }
            if(!isMobileDevice()){
                return (
                    <Icon
                        icon="gov__help-icon"
                        label="Help icon"
                        style="margin-left:auto"
                        onClickHandler={() => window.open(`${window.location.origin}/#/help-section`)}
                    />
                );
            }
        }
        // Keeps the empty div at a consistent height so the other text doesn't jump around
        return <div style={{ height: '15px', width: '23px' }} />;
    }

    const renderTopRightIcon = () =>{
        //if have logo, and not on the help-section screen show the logo
        if(logoChecker()){
            // If you're on the landing page but not using a mobile device, show the logo
            if (userState.onboardingIndex === stepIndex.LANDING_PAGE && window.location.hash.indexOf('help-section') == -1){
                return (
                    <img className="logo"src={logo} alt="Logo" />
                );
            }else if (window.location.hash.indexOf('help-section') == -1) {
                return (
                    <Icon
                        icon="gov__help-icon"
                        label="Help icon"
                        style="margin-left:auto"
                        onClickHandler={() => window.open(`${window.location.origin}/#/help-section`)}
                    />
                );
            }
        }
        else if (isMobileDevice() && userState.onboardingIndex === stepIndex.LANDING_PAGE) {
            return (
                <div
                    className="gov__language-nav"
                    role="button"
                    aria-pressed="false"
                    onClick={() => userDispatch({ type: SET_SIDEBAR, sidebarStatus: 'visible' })}
                >
                    <Icon
                        icon={`gov__language-icon ${userState.currentLanguage}`}
                        label="Language icon"
                    />
                    <span>{userState.currentLanguage.toUpperCase()}</span>
                </div>
            );
        }
    }
    return (
        <Fragment>
            {
                isModalOpen &&
                <Modal>
                    <div className="gov__modal-header">
                        <div className="gov__modal-banner" />
                        <div className="gov__modal-close" role="button" aria-pressed="false" onClick={() => setIsModalOpen(false)}>&times;</div>
                    </div>
                    <div className="gov__modal-content">
                        <Animation animationName="WARNING_TIMEOUT_ANIMATION" elementName="gov__warning-modal-icon" />
                        <div className="gov__warning-modal-container">
                            <h1>{t('shared.navbar-quit.title')}</h1>
                            <p>{t('shared.navbar-quit.content')}</p>
                            <Button onClickHandler={handleModalClick} text={t('shared.navbar-quit.yes-continue')} />
                        </div>
                    </div>
                </Modal>
            }
            <div className="gov__navbar">
                { renderArrow() }
                {(userState.onboardingIndex === 0 &&
                !(window.location.hash.indexOf('help-section') > -1) &&
                !(window.location.hash.indexOf('qr-code') > -1) &&
                !(window.location.hash.indexOf('incorrect-data') > -1)) &&

                        <div className="gov__logo" >
                            <div className="deloitte_logo">
                                <span className="gov__deloitte">Deloitte</span><span>.</span>
                            </div>
                        </div>
                }
                {(window.location.hash.indexOf('qr-code') > -1 ||
                window.location.hash.indexOf('incorrect-data') > -1) &&
                        <div className="gov__logo" style={{marginLeft:'auto', marginRight: 'auto'}}>
                            <div className="deloitte_logo">
                                <span className="gov__deloitte">Deloitte</span><span>.</span>
                            </div>
                        </div>
                }
                {userState.onboardingIndex !== 0 &&
                    <div className="gov__logo" style={{marginLeft:'auto', marginRight: 'auto'}}>
                        <span>Go Verify</span><span>.</span>
                    </div>
                }
                {window.location.hash.indexOf('help-section') > -1 &&
                    <div className="gov__logo" >
                        <span>Go Verify</span><span>.</span>
                    </div>
                }
                {renderHelpIcon()}
                {renderTopRightIcon()}
            </div>
        </Fragment>
    );
};

Navbar.propTypes = {
    match: PropType.object,
};

Navbar.defaultProps = {
    match: {},
};

export default Navbar;
