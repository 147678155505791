import React, { useContext, useEffect, useState } from 'react';
import PropType from 'prop-types';
import { useTranslation } from 'react-i18next';
import OfflinePage from "../landing-page/OfflinePage";
import i18next from "i18next";
import {
    SET_LANGUAGE,
    SET_LOADING,
    SET_APPLICATION_ONLINE
} from '../../state-management/actions/actionTypes';
// Context
import UserContext from '../../state-management/context/UserContext';
import { SET_INITIAL_STATE, SET_SESSION_EXPIRED } from '../../state-management/actions/actionTypes';

// Components
import Animation from '../animations/Animation';

// Consts
import api from '../../consts/api';

const SessionContainer = ({ children }) => {
    // Context variables
    const [userState, userDispatch] = useContext(UserContext);

    // Translation hook
    const { t } = useTranslation();

    // State variables
    const [error, setError] = useState('');

    useEffect(() => {
        initializeCookie()
        /* .catch(e => { console.log(e) }) */
        /*     .finally(() => {
                getApplicationOnlineStatus();
            }); */

        // eslint-disable-next-line
    }, []);

    // Refresh the users token on each navigation
    useEffect(() => {
        if (userState.onboardingIndex > 0) {
            api.get('/users/refresh');
        }
    }, [userState.onboardingIndex]);

    useEffect(() => {
        if (userState.user?.isoCountryOfHire) {
            if (userState.user.isoCountryOfHire === 'DEU' || userState.user.isoCountryOfHire === 'AUT') {
                i18next.changeLanguage('de');
                sessionStorage.setItem('language', 'de');
                userDispatch({ type: SET_LANGUAGE, currentLanguage: 'de' });
            } else if (userState.user.isoCountryOfHire === 'NLD') {
                i18next.changeLanguage('nl');
                sessionStorage.setItem('language', 'nl');
                userDispatch({ type: SET_LANGUAGE, currentLanguage: 'nl' });
            } else if (userState.user.isoCountryOfHire === 'POL') {
                i18next.changeLanguage('pl');
                sessionStorage.setItem('language', 'pl');
                userDispatch({ type: SET_LANGUAGE, currentLanguage: 'pl' });
            } else if (['ARG', 'BRA', 'CHL', 'ESP', 'MEX'].indexOf(userState.user.isoCountryOfHire) !== -1) {
                i18next.changeLanguage('es');
                sessionStorage.setItem('language', 'es');
                userDispatch({ type: SET_LANGUAGE, currentLanguage: 'es' });
            } else if (userState.user.isoCountryOfHire === 'FRA') {
                i18next.changeLanguage('fr');
                sessionStorage.setItem('language', 'fr');
                userDispatch({ type: SET_LANGUAGE, currentLanguage: 'fr' });
            }
        }
    }, [userState.user?.isoCountryOfHire]);

    /**
     * Check if the 'auth=' url param is present, following that either create the cookie or check if the cookie is still in date
     * and if authorized, retreieve the UUID for the user and other information from Salesforce.
     */
    /*     const initializeCookie = async () => {
            // Check if 'auth=' is in the URL
            const authUrlParamIndex = window.location.hash.indexOf('auth=');
    
            if (authUrlParamIndex >= 0) {
                createCookie(window.location.hash.split('auth=')[1]);
            } else {
                // Check if the cookie still exists and is still in date
                if (!isCookieFound()) {
                    userDispatch({ type: SET_SESSION_EXPIRED, isSessionExpired: true });
                } else {
                    fetchUserAndCountryInformation();
                    // fetchPrompts(userState.uuid);
                }
            }
        }; */

    const initializeCookie = async () => {
        // Check if 'auth=' is in the URL
        const authUrlParamIndex = window.location.hash.indexOf('auth=');

        if (authUrlParamIndex >= 0) {
            await createCookie(window.location.hash.split('auth=')[1]);
        } else {
            // Check if the cookie still exists and is still in date
            if (!isCookieFound()) {
                userDispatch({ type: SET_SESSION_EXPIRED, isSessionExpired: true });
            } else {
                await fetchUserAndCountryInformation();
                // fetchPrompts(userState.uuid);
            }
        }
    };

    /**
     * Check if the 'active' flag is in browser cookies
     */
    const isCookieFound = () => !!document.cookie.split('active=')[1];

    /**
     * Create a cookie for the browser and if successful, remove 'auth=' from the URL and reload the page.
     *
     * @param {string} uuid The unique identifier for the associated new hire
     */
    /*     const createCookie = (uuid) => {
            api.post('/users', { uuid })
                .then(() => {
                    fetchUserAndCountryInformation();
                    // fetchPrompts(uuid);
                })
                .catch((usersError) => {
                    if (usersError.hasOwnProperty('response') && usersError.response.data.statusCode === 404) {
                        setError(t('errors.link-expired'));
                    } else {
                        setError(t('errors.unexpected-error'));
                    }
                });
        }; */

    const createCookie = async (uuid) => {
        try {
            await api.post('/users', { uuid })
            fetchUserAndCountryInformation();
        }
        catch (usersError) {
            if (usersError.hasOwnProperty('response') && usersError.response.data.statusCode === 404) {
                setError(t('errors.link-expired'));
            } else {
                setError(t('errors.unexpected-error'));
            }
        }
    };

    /**
     * Fetch the inital information required from Salesforce
     */
    const fetchUserAndCountryInformation = async () => {
        try {
            const response = await api.get('/new-hires');
            createIntervalCheck();
            userDispatch({
                type: SET_INITIAL_STATE,
                countries: response.data.countries,
                isLoading: false,
                uuid: response.data.uuid,
                user: response.data.newHire,
            });

            if (window.location.hash.indexOf('help-section') < 0) {
                window.location.replace('/#/');
            }
        } catch (newHiresError) {
            if (newHiresError.response?.data?.statusCode === 403) {
                userDispatch({ type: SET_SESSION_EXPIRED, isSessionExpired: true });
            }
            if (newHiresError.response) {
                if (newHiresError.response?.data?.statusCode === 404) {
                    setError(t('errors.link-expired'));
                } else if (newHiresError.response?.data?.statusCode === 403) {
                    userDispatch({ type: SET_SESSION_EXPIRED, isSessionExpired: true });
                } else {
                    setError(`${newHiresError.response?.data?.message} (Error Code: ${newHiresError.response?.data?.statusCode})`);
                }
            } else {
                userDispatch({ type: SET_LOADING, isLoading: false });
                if (!window.location.href.includes("#/offline")) {
                    api.post('/new-hires/session-error');
                    setError(t('errors.unexpected-error'));
                }
            } /* else {
                console.log("The ELSE")
                userDispatch({ type: SET_LOADING, isLoading: false });
                api.post('/new-hires/session-error');
                setError(t('errors.unexpected-error'));
            } */
        }
    };

    /**
     * Create a five-second interval to check for the 'active' cookie with async
     */
    const createIntervalCheck = () => {
        const isSessionActiveTimeout = setInterval(() => {
            const cookieFlag = isCookieFound();

            // Check if the cookie exists
            if (!cookieFlag) {

                getApplicationOnlineStatus().then(() => {
                    userDispatch({ type: SET_SESSION_EXPIRED, isSessionExpired: true })
                    clearInterval(isSessionActiveTimeout);
                }).catch(e => {
                    console.log(e)
                    /* userDispatch({ type: SET_APPLICATION_ONLINE, applicationOnline: false }); */
                    clearInterval(isSessionActiveTimeout);
                })

                /* userDispatch({ type: SET_SESSION_EXPIRED, isSessionExpired: true })
                clearInterval(isSessionActiveTimeout); */
            }
        }, 5000);
    };

    // Function to call backend to check if variable is configured to take the application online
    const getApplicationOnlineStatus = async () => {
        return await api.get('/users/online',)
            .then((response) => {
                console.log('Application online status: ' + response.data);
                userDispatch({ type: SET_APPLICATION_ONLINE, applicationOnline: response.data });
            })
        /*  .catch(() => {
             setError(t('errors.unexpected-error'));
         }); */
    }

    if (userState.applicationOnline === false) {
        return (
            <OfflinePage />
        );
    }

    if (userState.isSessionExpired) {
        return (
            <div className="gov__container">
                <div className="gov__session-expired">
                    <div>
                        <Animation animationName="WARNING_TIMEOUT_ANIMATION" elementName="gov__warning-modal-icon" />
                        <h1>{t('session-expired.title')}</h1>
                        <p>{t('session-expired.content')}</p>
                    </div>
                </div>
            </div>
        );
    }

    if (error) {
        return <div className="gov__container"><div className="gov__session-expired">{error}</div></div>;
    }

    return children;
};

SessionContainer.propTypes = {
    children: PropType.array,
};

SessionContainer.defaultProps = {
    children: [],
};

export default SessionContainer;
