import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';

// Context
import HelpSectionContext from '../../state-management/context/HelpSectionContext';
import { RESET_SELECTED_ARTICLE } from '../../state-management/actions/actionTypes';

// Components
import Icon from '../shared/Icon';

const HelpSectionSelectedArticle = () => {
    // Context variables
    const [helpSectionState, helpSectionDispatch] = useContext(HelpSectionContext);

    // Translation hook
    const { t } = useTranslation();

    return (
        <div className="gov__selected-article">
            <p className="gov__topic-title">{helpSectionState.selectedArticle.category}</p>
            <h1>{helpSectionState.selectedArticle.title}</h1>
            <p>{helpSectionState.selectedArticle.description}</p>

            <div className="gov__help-section-read-more">
                <Icon icon="gov__chevron left help-section" label="Arrow left" onClickHandler={() => helpSectionDispatch({ type: RESET_SELECTED_ARTICLE })} />
                <div onClick={() => helpSectionDispatch({ type: RESET_SELECTED_ARTICLE })}>
                    <span className="gov__link">{t('help.back')}</span>
                </div>
            </div>
        </div>
    );
};

export default HelpSectionSelectedArticle;
