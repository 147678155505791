import React from 'react';
import PropType from 'prop-types';

const InputText = ({ error, label, onChangeHandler, placeholder, value, maxLength }) => {
    return (
        <div className="gov__form-group">
            <label>
                <p className="gov__label">{label}</p>

                <input
                    className={`gov__text-input ${error ? 'error' : ''}`}
                    autoComplete="new-password"
                    type="text"
                    placeholder={placeholder}
                    value={value}
                    onChange={onChangeHandler}
                    maxLength={maxLength}
                />
            </label>

            { error && <p className="gov__form-error">{error}</p> }
        </div>
    );
};

InputText.propTypes = {
    error: PropType.string,
    label: PropType.string,
    onChangeHandler: PropType.func,
    placeholder: PropType.string,
    value: PropType.string,
    maxLength: PropType.number
};

InputText.defaultProps = {
    error: '',
    label: '',
    onChangeHandler: null,
    placeholder: '',
    value: '',
    maxLength: null
};

export default InputText;
