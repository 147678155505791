export default {
    LANDING_PAGE: 0,
    WHY_DO_I_NEED_THIS_STEP: 1,
    PERSONAL_DETAILS_STEP: 2,
    UPLOAD_DOCUMENTS_STEP: 3,
    TERMS_AND_CONDITIONS_STEP: 4,
    BIOMETRICS_CAPTURE_STEP: 5,
    DYNAMIC_PROMPTS_STEP: 6,
    // DATE_OF_ARRIVAL_STEP: 6,
    DOCUMENTS_SELECTION_STEP: 7,
    DOCUMENT_CAPTURE_STEP: 8,
    RESULTS_STEP: 9,
};
