import React, {Fragment, useContext, useState} from 'react';
import {useTranslation} from 'react-i18next';

// Context
import UserContext from '../../state-management/context/UserContext';
import {
    SET_LOADING,
    SET_REPORT_ERRORS,
    UPDATE_INDEX_RESULTS
} from '../../state-management/actions/actionTypes';

// Components
// eslint-disable-next-line no-unused-vars
import Navbar from '../shared/Navbar';
import ContactForm from "../shared/ContactForm";
import api from "../../consts/api";
import results from "../../consts/results";
import Button from "../shared/Button";

const IncorrectData = () => {
    // Consts

    // Context variables
    const [userState, userDispatch] = useContext(UserContext);

    // Translation hook
    const {t} = useTranslation();

    // State variables
    // eslint-disable-next-line no-unused-vars
    const [errorMessage, setErrorMessage] = useState('');
    const [linkIsChecked, setLinkIsChecked] = useState(false);
    const [nameIsChecked, setNameIsChecked] = useState(false);
    const [countryIsChecked, setCountryIsChecked] = useState(false);
    const [otherIsChecked, setOtherIsChecked] = useState(false);

    const displayContactForm = () => {
        userDispatch({type: SET_REPORT_ERRORS, reportErrors: true});
    }

    // eslint-disable-next-line no-unused-vars
    const handleContactFormSubmit = (textInput) => {
        userDispatch({type: SET_LOADING, isLoading: true});

        api.put(`/new-hires/incorrect-information`, {
            errorTypes: {
                link: linkIsChecked,
                name: nameIsChecked,
                country: countryIsChecked,
                other: otherIsChecked
            },
            description: textInput
        })
            .then(() => {
                userDispatch({
                    type: UPDATE_INDEX_RESULTS,
                    result: results.PENDING_RESPONSE,
                });

                userDispatch({type: SET_LOADING, isLoading: false});

                // Return to the root url in order to display the results page
                window.location.replace(`${window.location.origin}/#/`);
            })
            .catch(() => {
                userDispatch({type: SET_LOADING, isLoading: false});
                setErrorMessage(t('errors.request-error'));
            });
    };

    return (
        <Fragment>
            <Navbar/>
            {!userState.reportErrors &&
            <div className="gov__incorrect-information-container">
                <p className="gov__incorrect-information-subtitle">{t('landing-page.incorrect-data.title')}</p>
                <p>{t('landing-page.incorrect-data.description.part-one')}</p>
                <p>{t('landing-page.incorrect-data.description.part-two')}</p>

                <div className='gov__incorrect-information-options'>
                    <p className="gov__incorrect-information-select-option">{t('landing-page.incorrect-data.select-option')}</p>
                    <div className='gov__gov__incorrect-information-option'>
                        <div className="gov__checkbox-container">
                            <label className="gov__checkbox-label">
                                <input type="checkbox"/>
                                <span className="gov__checkmark" role="button" aria-pressed="true"
                                      aria-label="Checkmark icon"
                                      onClick={() => setLinkIsChecked(!linkIsChecked)}/>
                            </label>
                        </div>
                        <div className="gov__option-text">
                            {t('landing-page.incorrect-data.options.link')}
                        </div>
                    </div>

                    <div className='gov__gov__incorrect-information-option'>
                        <div className="gov__checkbox-container">
                            <label className="gov__checkbox-label">
                                <input type="checkbox"/>
                                <span className="gov__checkmark" role="button" aria-pressed="false"
                                      aria-label="Checkmark icon"
                                      onClick={() => setNameIsChecked(!nameIsChecked)}/>
                            </label>
                        </div>
                        <div className="gov__option-text">
                            {t('landing-page.incorrect-data.options.name')}
                        </div>
                    </div>

                    <div className='gov__gov__incorrect-information-option'>
                        <div className="gov__checkbox-container">
                            <label className="gov__checkbox-label">
                                <input type="checkbox"/>
                                <span className="gov__checkmark" role="button" aria-pressed="false"
                                      aria-label="Checkmark icon"
                                      onClick={() => setCountryIsChecked(!countryIsChecked)}/>
                            </label>
                        </div>
                        <div className="gov__option-text">
                            {t('landing-page.incorrect-data.options.country')}
                        </div>
                    </div>

                    <div className='gov__gov__incorrect-information-option'>
                        <div className="gov__checkbox-container">
                            <label className="gov__checkbox-label">
                                <input type="checkbox"/>
                                <span className="gov__checkmark" role="button" aria-pressed="false"
                                      aria-label="Checkmark icon"
                                      onClick={() => setOtherIsChecked(!otherIsChecked)}/>
                            </label>
                        </div>
                        <div className="gov__option-text">
                            {t('landing-page.incorrect-data.options.other')}
                        </div>
                    </div>
                </div>

                <Button isDisabled={(!linkIsChecked && !nameIsChecked && !countryIsChecked && !otherIsChecked)}
                        onClickHandler={() => displayContactForm()} text={t('landing-page.incorrect-data.next')} />
            </div>
            }


            {userState.reportErrors &&
            <div className="gov__incorrect-information-container">
                <ContactForm
                    errorMessage={errorMessage}
                    onSubmit={handleContactFormSubmit}
                    subtext={t('landing-page.incorrect-data.description.part-three')}
                    title={t('landing-page.incorrect-data.title')}
                />
            </div>
            }


        </Fragment>
    );
};

export default IncorrectData;
