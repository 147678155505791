import React, { useContext, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { INCREMENT_INDEX, DECREMENT_INDEX, SET_LOADING, SET_SESSION_EXPIRED, UPDATE_DOCUMENTS } from '../../state-management/actions/actionTypes';

// Context
import UserContext from '../../state-management/context/UserContext';

import Button from '../shared/Button';

import api from '../../consts/api';


const DynamicPrompts = () => {
    const { t } = useTranslation();

    // Context variables
    const [userState, userDispatch] = useContext(UserContext);
    const [answers, setAnswers] = useState([]);
    const [currentQuestionId, setCurrentQuestionId] = useState(userState?.prompts[0]?.id);

    const [updateError, setUpdateError] = useState('');

    // Use a new state variable to track question history.
    const [questionHistory, setQuestionHistory] = useState([userState?.prompts[0]?.id]);


    const [isCheckedAnswer, setIsCheckedAnswer] = useState(false);
    
    // Handle answer change
    const handleAnswer = (currentQuestion, answer, event) => {
        const { id: questionId, pdfReport, type, question } = currentQuestion; // Destructure the needed properties
        setAnswers(prevState => {
            const index = prevState.findIndex(a => a.id === questionId);
            if (index !== -1) {
                if(event.target.type === "checkbox"){
                    if(event.target.checked){
                        prevState[index].answer = answer;
                        prevState[index].pdfReport = pdfReport;
                        prevState[index].type = type;
                        prevState[index].question = question;
                    } else {
                        prevState[index].answer = {};
                        prevState[index].pdfReport = '';
                        prevState[index].type = type;
                        prevState[index].question = question;
                    }
                } else {
                    prevState[index].answer = answer;
                    prevState[index].pdfReport = pdfReport;
                    prevState[index].type = type;
                    prevState[index].question = question;
                }
            } else {
                prevState.push({ id: questionId, answer, pdfReport, type, question });
            }
            return [...prevState];
        });
        setIsCheckedAnswer(event.target.checked);
    };
    
    
// Go to the next question
const nextQuestion = async () => {
    setIsCheckedAnswer(false);
    const currentAnswer = answers.find(answer => answer.id === currentQuestionId);
    if (currentAnswer && currentAnswer.answer.followUpPrompt) {
        setCurrentQuestionId(currentAnswer.answer.followUpPrompt);
        if (currentAnswer.answer.followUpPrompt) {
            setQuestionHistory([...questionHistory, currentAnswer.answer.followUpPrompt]);
        }        
    } else {
        const patchData = answers.map(answer => ({
            id: answer.id,
            pdfReport: answer.pdfReport,
            type: answer.type,
            question: answer.question,
            answers: [{
                value: answer.answer.value,
                label: answer.answer.label,
            }]
        }));

        try {
            userDispatch({ type: SET_LOADING, isLoading: true });
            const response = await api.post('/new-hires/get-document-combination', { prompts: patchData });

            userDispatch({
                type: UPDATE_DOCUMENTS,
                documentCombinations: {
                    isDocumentPrivacyRequired: response.data.documentCombinations.isDocumentPrivacyRequired,
                    preferredDocuments: response.data.documentCombinations.preferredDocuments,
                    nonPreferredDocuments: response.data.documentCombinations.nonPreferredDocuments,
                    category: response.data.documentCombinations.category
                },
            });
            userDispatch({ type: SET_LOADING, isLoading: false });
            userDispatch({ type: INCREMENT_INDEX });

        } catch (error) {
            console.error('error: ', error);
            userDispatch({ type: SET_LOADING, isLoading: false });

            if (error.response && error.response.status === 403) {
                userDispatch({ type: SET_SESSION_EXPIRED, isSessionExpired: true });
            }
            setUpdateError(error.response?.data?.message || t('errors.update-error'));
        }
    }
}

// Go to the previous question
const previousQuestion = () => {
    if (questionHistory.length > 1) {
        const newHistory = questionHistory.slice(0, -1);
        setQuestionHistory(newHistory);
        setCurrentQuestionId(newHistory[newHistory.length - 1]);
        setAnswers(prevState => prevState.filter(answer => newHistory.includes(answer.id)));
    } else if (questionHistory.length === 1) {
        setCurrentQuestionId(questionHistory[0]);
        setAnswers([]);
    } else {
        userDispatch({ type: DECREMENT_INDEX });
    }
};


    // Ensure the new hire is at the top of the page
    useEffect(() => window.scroll(0, 0), [currentQuestionId]);


    const currentQuestion = userState.prompts.find(prompt => prompt.id === currentQuestionId);
    
    return (
        <div className="gov__dynamic_prompts">
            <p className="gov__step-title">{t('shared.step-one')}</p>
            <div className="gov__dynamic_prompts-question">
                {currentQuestion.question.split('\n').map((line, i) => line.startsWith('-') ? <li key={i}>{line.substring(1).trim()}</li> : <p key={i} className='gov__label'>{line}</p>)}
                { currentQuestion ? (
                        currentQuestion.answers.map((answer, i) => {
                            const answerId = `${currentQuestion.id}-answer-${i}`;
                            const currentAnswer = answers.find(a => a.id === currentQuestion.id);
                            let isChecked;
                            currentQuestion.type === 'checkbox' ? isChecked = currentAnswer && Object.keys(currentAnswer.answer).length > 0 : isChecked = currentAnswer && currentAnswer.answer.value === answer.value
                            
                                return (
                                    <div key={answerId || i} className="gov__checkbox-container">
                                        <label className={currentQuestion.type === "checkbox" ? "gov__checkbox-label" : "gov__dynamic_prompts-buttons radio"}>
                                            <input className={currentQuestion.type === "radio" ? "gov__radio-btn" : ""} id={answerId} type={currentQuestion.type} value={answer.value || ''} name={currentQuestion.id} onChange={() => handleAnswer(currentQuestion, answer, event)} checked={isChecked} />
                                            <label htmlFor={answerId} className="radio-label">{answer.label}</label>
                                            { currentQuestion.type === "checkbox" ? (<span className="gov__checkmark" role="button" aria-pressed="false" aria-label="Checkmark icon" onClick={() => setIsCheckedAnswer(!isChecked)} />) : (<></>) }
                                        </label>
                                    </div>
                                )
                            }
                        )
                    ) : (
                        <p>{t('errors.no-prompts')}</p>
                    )
                }
            </div>
            { updateError && <p className="gov__form-error">{updateError}</p> }
            <div className="gov__form-group">
                <Button onClickHandler={previousQuestion} text={'Back'}/>
                <Button onClickHandler={nextQuestion} text={'Next'} isDisabled={!isCheckedAnswer}/>
            </div>
        </div>
    );
};

export default DynamicPrompts;
