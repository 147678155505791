import React, { useContext } from 'react';
import PropType from 'prop-types';
import { useTranslation } from 'react-i18next';

// Context
import HelpSectionContext from '../../state-management/context/HelpSectionContext';
import { UPDATE_SEARCH_TEXT } from '../../state-management/actions/actionTypes';

const HelpSectionSearch = ({ articles }) => {
    // Context variables
    const [helpSectionState, helpSectionDispatch] = useContext(HelpSectionContext);

    // Translation hook
    const { t } = useTranslation();

    const handleSearchInput = (text) => {
        const foundArticles = [];

        // Loop through the article categories (Popular articles, Personal information, etc)
        Object.keys(articles).forEach((category) => {
            for (const article of articles[category]) {
                const tempWordCount = findOccurrences(article, text.toLowerCase());

                if (tempWordCount > 0) {
                    foundArticles.push({ article, count: tempWordCount });
                }
            }
        });

        foundArticles.sort((a, b) => b.count - a.count);
        const tempArticles = [];

        for (const foundArticle of foundArticles) {
            tempArticles.push(foundArticle.article);
        }

        helpSectionDispatch({ type: UPDATE_SEARCH_TEXT, filteredArticles: tempArticles, text });
    };

    /**
     * Loop through the description, subtext and title to find how many occurances of the text
     *
     * @param {object} article the article object we will search through
     * @param {string} textToFind the text entered by the user
     * @returns 
     */
    const findOccurrences = (article, textToFind) => {
        let foundOccurrance = false;
        let index = 0;
        let total = 0;

        if (textToFind === '') {
            return total;
        }

        do {
            // If document is using HTML tags return it as a string rather than an object - otherwise just use the regular description
            const description = typeof article.description === 'string' ? article.description.toLowerCase() : t(article.description.props.i18nKey.toLowerCase());
            index = description.indexOf(textToFind, index);

            if (index >= 0) {
                index += 1;
                total += 1;
                foundOccurrance = true;
            } else {
                foundOccurrance = false;
            }
        } while (foundOccurrance);

        // Check subtexts
        index = 0;

        do {
            index = article.subtext.toLowerCase().indexOf(textToFind, index);

            if (index >= 0) {
                index += 1;
                total += 1;
                foundOccurrance = true;
            } else {
                foundOccurrance = false;
            }
        } while (foundOccurrance);

        // Check titles
        index = 0;

        do {
            index = article.title.toLowerCase().indexOf(textToFind, index);

            if (index >= 0) {
                index += 1;
                total += 1;
                foundOccurrance = true;
            } else {
                foundOccurrance = false;
            }
        } while (foundOccurrance);

        return total;
    };

    return (
        <input
            className="gov__help-search"
            aria-label="Search Help Articles"
            placeholder={t('help.search.placeholder')}
            onChange={(event) => handleSearchInput(event.target.value)}
            type="text"
            value={helpSectionState.searchText}
        />
    );
};

HelpSectionSearch.propTypes = {
    articles: PropType.object,
};

HelpSectionSearch.defaultTypes = {
    articles: null,
};

export default HelpSectionSearch;
