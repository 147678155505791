import React, { useReducer } from "react";
import { HashRouter, Route, Routes } from "react-router-dom";

// Context and Reducers
import UserContext from "../state-management/context/UserContext";
import UserReducer from "../state-management/reducers/UserReducer";

// Components
import HelpSectionScreen from "./help-section/HelpSectionScreen";
import IncorrectData from "./landing-page/IncorrectData";
import QrCodeDetail from "./landing-page/QrCodeDetail";
import LoadingSpinner from "./shared/LoadingSpinner";
import RightToWorkContainer from "./shared/RightToWorkContainer";
import SessionContainer from "./shared/SessionContainer";
import Sidebar from "./shared/sidebar/Sidebar";
import GeoBlocked from "./landing-page/GeoBlocked";
import OfflinePage from "./landing-page/OfflinePage";

const App = () => {
  const initialUserState = {
    countries: [],
    currentLanguage: sessionStorage.getItem("language") || "en",
    documentCombinations: [],
    documentIndex: 0,
    isLoading: true,
    loadingMessage: "",
    isNavigationBlocked: false,
    isNoDocumentsAvailableFlow: false,
    onboardingIndex: 0,
    result: "",
    selectedDocumentCombination: [],
    isSessionExpired: false,
    sidebarStatus: "",
    user: null,
    uuid: null,
    vendor: { needsDateFilter: false },
    reportErrors: false,
    prompts: [],
  };

  const userReducer = (state, action) => UserReducer(state, action);
  const [userState, userDispatch] = useReducer(userReducer, initialUserState);

  return (
    <UserContext.Provider value={[userState, userDispatch]}>
      <SessionContainer>
        <Sidebar />
        {userState.isLoading ? (
          <LoadingSpinner />
        ) : (
          <HashRouter>
            <Routes>
              <Route exact path="/" element={<RightToWorkContainer />} />
              <Route
                exact
                path="/help-section"
                element={<HelpSectionScreen />}
              />
              <Route exact path="/incorrect-data" element={<IncorrectData />} />
              <Route exact path="/qr-code" element={<QrCodeDetail />} />
              <Route exact path="/geoblocked" element={<GeoBlocked />} />
              <Route exact path="/offline" element={<OfflinePage />} />
            </Routes>
          </HashRouter>
        )}
      </SessionContainer>
    </UserContext.Provider>
  );
};

export default App;
