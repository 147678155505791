/* eslint-disable react/jsx-closing-bracket-location */
import React, { useEffect, useState, useContext } from 'react';
import { useTranslation } from 'react-i18next';

// Context
import UserContext from '../../state-management/context/UserContext';
import {
    SELECT_DOCUMENT_COMBINATION,
    SET_LOADING,
    SET_NO_DOCUMENTS_AVAILABLE,
    SET_SESSION_EXPIRED,
    UPDATE_INDEX_RESULTS
} from '../../state-management/actions/actionTypes';

// Components
import ContactForm from '../shared/ContactForm';
import DocumentCombinationTile from './DocumentCombinationTile';
import DocumentSelectionHeader from './DocumentSelectionHeader';

// Consts
import api from '../../consts/api';
import results from '../../consts/results';
import Icon from '../shared/Icon';

const DocumentSelection = () => {
    // Context
    const [userState, userDispatch] = useContext(UserContext);

    // console.log('vendor', userState.vendor)

    // Translation hook
    const { t } = useTranslation();

    // State Variables
    const [errorMessage, setErrorMessage] = useState('');
    const [isDocumentPrivacyRequired, setIsDocumentPrivacyRequired] = useState(false);
    const [isOtherDocumentsShowing, setIsOtherDocumentsShowing] = useState(false);
    const [otherDocumentCombinations, setOtherDocumentCombinations] = useState([]);
    const [preferredDocumentCombination, setPreferredDocumentCombination] = useState([]);

    useEffect(() => {
        setupDocumentCombinations();

        // eslint-disable-next-line
    }, []);

    /**
     * Build out the preferred and other document combinations
     */
    const setupDocumentCombinations = () => {
        const preferredCombinationTemp = userState.documentCombinations.preferredDocuments;
        const otherCombinationsTemp = userState.documentCombinations.nonPreferredDocuments;

        if (!preferredCombinationTemp) {
            setIsOtherDocumentsShowing(true);
        } else {
            setPreferredDocumentCombination(preferredCombinationTemp);
        }

        setOtherDocumentCombinations(otherCombinationsTemp);
        setIsDocumentPrivacyRequired(userState.documentCombinations.isDocumentPrivacyRequired);
    };

    /**
     * Grab the document selection object and add the documents to global state
     *
     * @param {object} documentCombination the selected document combination object
     */
    const handleDocumentCombinationSelection = async (documentCombination) => {
        userDispatch({ type: SET_LOADING, isLoading: true });

        if (isDocumentPrivacyRequired) {
            // Set the new hires document combination choice
            await api.put(`/new-hires/document-privacy`, {
                combinationId: documentCombination.documentCombinationId,
            });

            // Forward the user to the results page
            userDispatch({
                type: UPDATE_INDEX_RESULTS,
                result: results.PENDING_F2F_CHECK,
            });
        } else {
            // Set the new hires document combination of choice
            await api.post(`/new-hires/document-combination`, {
                applicantId: userState.vendor.id,
                combinationId: documentCombination.documentCombinationId,
            })
            .catch((error) => {
                if(error.response.data.statusCode === 403) {
                    userDispatch({ type: SET_SESSION_EXPIRED, isSessionExpired: true });
                }
            });

            api.post(`/logging/document-combination`, {
                uuid: userState.uuid,
                documentCombination: documentCombination
            })
            .catch((error) => {
                if(error.response.data.statusCode === 403) {
                    userDispatch({ type: SET_SESSION_EXPIRED, isSessionExpired: true });
                }
            });

            userDispatch({
                type: SELECT_DOCUMENT_COMBINATION,
                selectedDocumentCombination: documentCombination,
            });
        }
    };

    /**
     * Receive the event string from the child component and sent the text to the backend
     *
     * @param {string} text document issue text
     */
    const handleContactFormSubmit = (text) => {
        api.put(`/new-hires/document-issues`, { issueText: text })
        .then(() => userDispatch({
            type: UPDATE_INDEX_RESULTS,
            result: results.PENDING_RESPONSE,
        }))
        .catch((error) => {
            if (error.response) {
                if (error.response.status === 403) {
                    userDispatch({ type: SET_SESSION_EXPIRED, isSessionExpired: true });
                } else {
                    setErrorMessage(t('errors.update-error'));
                }
            }
        })
    };

    /**
     * Update the global state to show the flow for a new hire having no documents
     */
    const handleNoDocumentsAvailableClick = () => userDispatch({ type: SET_NO_DOCUMENTS_AVAILABLE, isNoDocumentsAvailableFlow: true });

    if (userState.isNoDocumentsAvailableFlow) {
        return <ContactForm
                    errorMessage={errorMessage}
                    onSubmit={handleContactFormSubmit}
                    subtext={t('document-selection.no-documents.description')}
                    title={t('document-selection.no-documents.title')}
                />;
    }

    return (
        <div className="gov__document-selection-container">
            <DocumentSelectionHeader isDocumentPrivacyRequired={isDocumentPrivacyRequired} />

            {
                preferredDocumentCombination.length === 1 &&
                <div>
                    <div className="gov__document-combination-type">
                        <Icon icon="gov__document-combo-preferred" label="Preferred document icon" />
                        <p>{t('document-selection.preferred-option')}</p>
                    </div>
                </div>
            }

            {
                preferredDocumentCombination.length > 1 &&
                <div>
                    <div className="gov__document-combination-type">
                        <Icon icon="gov__document-combo-preferred" label="Preferred document icon" />
                        <p>{t('document-selection.preferred-options')}</p>
                    </div>
                </div>
            }

            {
                preferredDocumentCombination.map((preferredDocumentCombination, preferredDocumentCombinationIndex) => {
                    return (
                          <DocumentCombinationTile documentCombination={preferredDocumentCombination}
                                                   handleDocumentCombinationSelection={handleDocumentCombinationSelection}
                                                   key={preferredDocumentCombinationIndex} />
                    );
                })
            }

            {
                otherDocumentCombinations.length > 0 ? (
                    !isOtherDocumentsShowing ? (
                        <div className="gov__other-documents-link">
                            <span className="gov__link" role="button" aria-pressed="false" onClick={() => setIsOtherDocumentsShowing(true)}>
                                {t('document-selection.show-more')}
                            </span>
                        </div>
                    ) : (
                        <div>
                            <div className="gov__document-combination-type">
                                <Icon icon="gov__document-combo-other" label="Preferred document icon" />
                                <p>{t('document-selection.other-options')}</p>
                            </div>

                            {
                                otherDocumentCombinations.map((documentCombination, documentCombinationIndex) => {
                                    return (
                                        <DocumentCombinationTile
                                            documentCombination={documentCombination}
                                            handleDocumentCombinationSelection={handleDocumentCombinationSelection}
                                            key={documentCombinationIndex}
                                        />
                                    );
                                })
                            }
                            <div className="gov__other-documents-link">
                                <span
                                    className="gov__link"
                                    role="button"
                                    aria-pressed="false"
                                    onClick={handleNoDocumentsAvailableClick}
                                >
                                    {t('document-selection.cannot-provide')}
                                </span>
                            </div>
                        </div>
                    )
                ) : (
                    <div className="gov__other-documents-link">
                        <span
                            className="gov__link"
                            role="button"
                            aria-pressed="false"
                            onClick={handleNoDocumentsAvailableClick}
                        >
                            {t('document-selection.cannot-provide')}
                        </span>
                    </div>
                )
            }
        </div>
    );
};

export default DocumentSelection;
